// Navigation.jsx

import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from '../provider/auth';
import Calculadora from '../screens/Calculadora';
import Dashboard from '../screens/Dashboard';
import DetallePropiedad from '../screens/DetallePropiedad';
import DetallePropiedadPreview from '../screens/DetallePropiedadPreview';
import Documents, { default as DocumentsHome } from '../screens/Documents';
import Help from '../screens/HelpPage';
import Home from '../screens/HomePage';
import Loading from '../screens/Loading';
import Logout from '../screens/Logout';
import MapPage from '../screens/MapPage'; // Importa el nuevo componente
import Login from '../screens/auth/Login';
import Register from '../screens/auth/Register';
import Conversation from '../screens/chats/conversation';
import Chats from '../screens/chats/index';
import ModDocuments from '../screens/documents/mod-documents';
import AvisoPrivacidad from '../screens/legal/aviso-privacidad';
import TermsAndConditions from '../screens/legal/terminos-y-condiciones';
import Notificaciones from '../screens/notifications/index';
import NotificacionesSettings from '../screens/notifications/settings';
import EvaluateLandlord from "../screens/personal-panel/EvaluateLandlord";
import MyAlerts from "../screens/personal-panel/MyAlerts";
import MyDocuments from "../screens/personal-panel/MyDocuments";
import MyEval from "../screens/personal-panel/MyEval";
import MyLikes from "../screens/personal-panel/MyLikes";
import MyScore from "../screens/personal-panel/MyScore";
import PersonalInfo from "../screens/personal-panel/PersonalInfo";
import PersonalPanel from "../screens/personal-panel/PersonalPanel";
import MainPublish from '../screens/properties/main-publish';
import PlanUpload from '../screens/properties/propertie/upload-plan';
import CompletePassport from '../screens/personal-panel/CompletePassport';
import CheckoutFormPassport from '../screens/personal-panel/PassportPaymentForm';
import ReportSection from '../components/properties/report-section';
import Nosotros from '../screens/Nosotros';
import MyDocumentsEdit from '../screens/personal-panel/MyDocumentsEdit';
import InvestigationQuestions from '../screens/personal-panel/Questions';
import ForgotPassword from '../screens/auth/ForgotPassword';
import { useLocation } from 'react-router-dom';
import { trackEvent } from '../services/track.service';
import MyReferrals from '../screens/personal-panel/MyReferrals';
import MyMembership from '../screens/personal-panel/MyMembership';
import NotFound from '../screens/errors/NotFound';
import ValidateIdentity from '../screens/personal-panel/ValidateIdentity';


const Navigation = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', process.env.REACT_APP_GOOGLE_ADS_ID, {
        page_path: location.pathname,
      });
    }
    trackEvent(location.pathname);
  }, [location]);


  const auth = useContext(AuthContext);
  const user = auth.user;

  const [hasDocuments, setHasDocuments] = useState(null);

  useEffect(() => {
    if (user && user.user && Array.isArray(user.user.documents) && user.user.documents.length > 0 && hasDocuments === null) {
      setHasDocuments(true);
    }
  }, [user, hasDocuments]);


  if (user === null) {
    return <Loading />;
  }

  console.log(user == null, hasDocuments);

  return (

    <Routes>
      {/* Publicas */}
      <Route path="/" element={<MapPage />} />
      <Route path="propiedad/:id/detalle" element={<DetallePropiedad />} />
      <Route path="calculadora" element={<Calculadora />} />
      <Route path="aviso-de-privacidad" element={<AvisoPrivacidad />} />
      <Route path="terminos-y-condiciones" element={<TermsAndConditions />} />
      <Route path="propiedad/previa/detalle" element={<DetallePropiedadPreview />} />
      <Route path="nosotros" element={<Nosotros />} />

      {Object.keys(user).length === 0 && (
        <>
          {/* Rutas de autenticación */}

          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/forgot" element={<ForgotPassword />} />
        </>
      )}

      {Object.keys(user).length > 0 && (
        <>
          {/* Rutas de administración */}
          <Route path="/auth/login" element={<Login />} />
          <Route path="/admin/home" element={<Home />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/plan-upload" element={<PlanUpload />} />
          <Route path="/admin/documents/edit" element={<ModDocuments />} />
          <Route path="/admin/documents" element={hasDocuments ? <DocumentsHome /> : <Documents />} />
          <Route path="/admin/help" element={<Help />} />
          <Route path="/admin/publish-propertie" element={<MainPublish hasDocuments={!!hasDocuments} />} />
          <Route path="/admin/chats" element={<Chats />} />
          <Route path="/admin/chats/:chatId" element={<Chats />} />
          <Route path="/admin/chat/conversation" element={<Conversation />} />
          <Route path="/admin/notificaciones" element={<Notificaciones />} />
          <Route path="/admin/notificacionesSettings" element={<NotificacionesSettings />} />
          <Route path="/admin/propiedad/:id/editar" element={<MainPublish hasDocuments={!!hasDocuments} isEditing={true} />} />

          <Route path="/admin/logout" element={<Logout />} />

          {/* Nuevas rutas */}
          <Route path="/admin/personal-panel" element={<PersonalPanel />}>
            <Route path="data-personal" element={<PersonalInfo />} />
            <Route path="my-eval" element={<MyEval />} />
            <Route path="evaluate-landlord" element={<EvaluateLandlord />} />
            <Route path="my-score" element={<MyScore />} />
            <Route path="my-documents" element={<MyDocuments />} />
            <Route path="my-likes" element={<MyLikes />} />
            <Route path="complete-passport" element={<CompletePassport />} />
            <Route path="passport-payment-form" element={<CheckoutFormPassport />} />
            <Route path="report" element={<ReportSection />} />
            <Route path="my-alerts" element={<MyAlerts />} />
            <Route path="my-referrals" element={<MyReferrals />} />
            <Route path="my-membership" element={<MyMembership />} />
            <Route path="documents-upload" element={<MyDocumentsEdit />} />
            <Route path="investigation-questions" element={<InvestigationQuestions />} />
            <Route path="validate-identity" element={<ValidateIdentity />} />
          </Route>

          {/* Rutas obsoletas comentadas */}
          {/* <Route path='/admin/info' element={hasDocuments ? <PersonalPanel /> : <Info />} /> */}
        </>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>


  );
};

export default Navigation;
