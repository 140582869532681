import {
    faUser,
    faEnvelope,
    faPhone,
    faMessageLines
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react';
import Spinner from '../Loader/Spinner';
import CustomModal from '../modals/modal-custom-info';
import ErrorModal from '../modals/modal-error';
import { sendContactForm } from '../../services/auth.service';
import { forwardRef, useRef, useImperativeHandle } from "react";

const ContactCard = forwardRef(({ propertyId }, ref) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showCustomModal, setShowCustomModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const title = 'Mensaje enviado al anunciante';
    const text = 'Te enviaremos un correo cuando haya contestado tu mensaje.';
    const titleError = 'Error al enviar mensaje';
    const textError = 'Comunicate con soporte para resolverlo a la brevedad.';
    const inputRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useImperativeHandle(ref, () => ({
        focusInput: () => {
            if (inputRef.current) {
                inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
                setTimeout(() => inputRef.current.focus(), 300);
            }
        },
    }));

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'El nombre es requerido';
        if (!formData.email) {
            newErrors.email = 'El correo es requerido';
        } else if (!/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
            newErrors.email = 'El correo no es válido';
        }

        if (!formData.phone) {
            newErrors.phone = 'El teléfono es requerido';
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'El teléfono debe tener 10 dígitos';
        }

        if (!formData.message) newErrors.message = 'El mensaje es requerido';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleContactClick = async () => {
        setIsLoading(true);
        try {
            if (validateForm()) {
                const resp = await sendContactForm(formData, propertyId);
                if (resp.status === 201) {
                    setShowCustomModal(true);
                }

                
            }  
        }
        catch (error) {
            const response = error.response;
            if (response.status === 400 && response.data.message === "You are the owner of this property") {
                setErrors({ response: 'No puedes contactar con tu propio anuncio' });
            } else {
                setErrors({ response: 'Ha ocurrido un error al enviar el formulario, comunícate con soporte para resolverlo a la brevedad.' });
            }
            console.error("Error al enviar formulario", error)
        }
        finally {
            setIsLoading(false);
        }
    };

    const closeCustomModal = () => {
        setShowCustomModal(false);
    }

    const closeErrorModal = () => {
        setShowErrorModal(false);
    }

    return (
        <div className="w-full mx-auto p-6 border border-gray-300 rounded-lg shadow-md bg-white">
            <h2 className="text-xl font-semibold text-gray-800 text-center mb-4">Contactar al Anunciante</h2>
            <form>
                <div className="relative mb-4" ref={ref}>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className={`block px-4 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-white rounded-lg border ${errors.name ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:border-azul peer`}
                        placeholder=" "
                        disabled={isLoading}
                        ref={inputRef}
                    />
                    <label
                        htmlFor="name"
                        className="cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] ml-2 bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3">
                        Nombre
                    </label>
                    {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
                </div>

                <div className="relative mb-4">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className={`block px-4 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-white rounded-lg border ${errors.email ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:border-azul peer`}
                        placeholder=" "
                        disabled={isLoading}
                    />
                    <label
                        htmlFor="email"
                        className="cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] ml-2 bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3">
                        Correo
                    </label>
                    {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
                </div>

                <div className="relative mb-4">
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        className={`block px-4 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-white rounded-lg border ${errors.phone ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:border-azul peer`}
                        placeholder=" "
                        disabled={isLoading}
                    />
                    <label
                        htmlFor="phone"
                        className="cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] ml-2 bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3">
                        Telefono
                    </label>
                    {errors.phone && <p className="text-red-500 text-xs">{errors.phone}</p>}
                </div>

                <div className="relative mb-4">
                    <textarea
                        id=""
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className={`block px-4 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-white rounded-lg border ${errors.message ? 'border-red-500' : 'border-gray-300'}  appearance-none focus:outline-none focus:border-azul peer`}
                        placeholder=" "
                        disabled={isLoading}
                    />
                    <label
                        htmlFor="message"
                        className="cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] ml-2 bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3">
                        Mensaje
                    </label>
                    {errors.message && <p className="text-red-500 text-xs">{errors.message}</p>}
                </div>

                <div className="flex flex-col gap-2 mt-4">
                    {errors.response && <p className="text-red-500 text-xs">{errors.response}</p>}
                    <button
                        type="button"
                        onClick={handleContactClick}
                        className={`w-full py-2 text-white ${isLoading ? '' : 'bg-azul hover:bg-blue-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'} `}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <div className="flex justify-center items-center">
                                <Spinner />
                            </div>) : 'Contactar'}
                    </button>
                    <p className="text-xs cursor-pointer" onClick={() => window.open("https://yorento.com/terminos-y-condiciones.pdf", "_blank")}>Al enviar estas aceptando los Términos y condiciones del Uso y la Política de Privacidad</p>
                </div>
            </form>
            <CustomModal show={showCustomModal} text={text} title={title} color={'gray'} onClose={closeCustomModal} />
            <ErrorModal show={showErrorModal} text={textError} title={titleError} onClose={closeErrorModal} />
        </div>
    );
});

export default ContactCard;
