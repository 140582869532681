import { faStar, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { updatePropertieStatus } from '../../services/properties.service';
import { postLessorScore } from '../../services/score.service';

const LessorEvaluation = ({ lessor, rentHistoryId, onClose, property }) => {
    const [rate, setRate] = useState(null);
    const [public_comment, setPublic_comment] = useState('');
    const [private_comment, setPrivateComment] = useState('');
    const [compliance_stars, setComplianceStars] = useState(0);
    const [cleanly_stars, setCleanlyStars] = useState(0);
    const [expectations_stars, setexpectationsStars] = useState(0);
    const [kindness_stars, setkindnessStars] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const { token } = useSelector(state => state.auth);

    if (!lessor) return <div>Cargando...</div>;

    const validateFields = () => {
        const newErrors = {};
        if (!rate) newErrors.rate = 'Este campo es obligatorio';
        if (!public_comment) newErrors.publicComment = 'Este campo es obligatorio';
        if (!private_comment) newErrors.privateComment = 'Este campo es obligatorio';
        if (!compliance_stars) newErrors.complianceStars = 'Este campo es obligatorio';
        if (!cleanly_stars) newErrors.cleanlyStars = 'Este campo es obligatorio';
        if (!expectations_stars) newErrors.expectationsStars = 'Este campo es obligatorio';
        if (!kindness_stars) newErrors.kindnessStart = 'Este campo es obligatorio';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleEvaluationSubmit = async () => {

        if (!validateFields()) return;
        setIsLoading(true);
        setMessage(null);

        const evaluationData = {
            rate,
            public_comment,
            compliance_stars,
            cleanly_stars,
            expectations_stars,
            kindness_stars,
            private_comment,
            rentHistoryId
        };

        try {
            await postLessorScore(evaluationData, token);
            setMessage('Evaluación enviada con éxito.');

            const payload = {
                is_rented: false,
            };

            setIsLoading(false);
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            setMessage('Error al enviar la evaluación. Inténtalo nuevamente.');
            setIsLoading(false);
        }
    };

    const starVariants = {
        initial: { scale: 1 },
        hover: { scale: 1.3, rotate: 15 },
    };

    return (
        <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            onClick={onClose}
        >
            <motion.div
                className="px-6 py-4 space-y-6 bg-white rounded-lg shadow-lg max-w-md w-full max-h-[90vh] overflow-y-auto overflow-x-hidden"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex justify-between items-center px-4 py-3">
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-600 transition ml-auto text-md">
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </button>
                </div>
                <motion.h2
                    className="text-center text-2xl font-semibold text-purple-600"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    Evalúa a tu arrendador(a)
                </motion.h2>
                <div className="flex flex-col items-center mb-6">
                    <motion.img
                        src={lessor.profile_picture}
                        alt={lessor.name}
                        className="w-24 h-24 rounded-full mb-2 object-cover"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    />
                    <p className="text-lg font-medium text-center">{lessor.name}</p>
                </div>
                <div className="mb-6 text-center">
                    <p className="text-gray-700 mb-2">¿Qué calificación le darías?</p>
                    <div className="flex justify-center space-x-4">
                        {['Excelente', 'Buena', 'Regular', 'Mala'].map((label, index) => (
                            <motion.button
                                key={index}
                                className={`px-4 py-3 rounded-full transition-colors duration-200 ${rate === label ? 'bg-purple-600 text-white' : 'bg-gray-200 text-gray-700'
                                    }`}
                                onClick={() => setRate(label)}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                {label}
                            </motion.button>
                        ))}
                    </div>
                    {errors.rate && <p className="text-red-500 mt-2">{errors.rate}</p>}
                </div>
                <div className="mb-4 text-center">
                    <p className="text-gray-700 mb-2">¿Cumplió las normas convenidas en el contrato?</p>
                    <div className="flex justify-center">
                        {[1, 2, 3, 4, 5].map((rating) => (
                            <motion.div
                                key={rating}
                                initial="initial"
                                whileHover="hover"
                                variants={starVariants}
                                transition={{ duration: 0.2 }}
                            >
                                <FontAwesomeIcon
                                    icon={faStar}
                                    className={`cursor-pointer ${compliance_stars >= rating ? 'text-purple-600' : 'text-gray-300'
                                        }`}
                                    style={{ fontSize: '2rem' }}
                                    onClick={() => setComplianceStars(rating)}
                                />
                            </motion.div>
                        ))}
                    </div>
                    {errors.complianceStars && <p className="text-red-500 mt-2">{errors.complianceStars}</p>}
                </div>
                <div className="mb-4 text-center">
                    <p className="text-gray-700 mb-2">¿Te entregó el inmueble limpio y en buenas condiciones?</p>
                    <div className="flex justify-center">
                        {[1, 2, 3, 4, 5].map((rating) => (
                            <motion.div
                                key={rating}
                                initial="initial"
                                whileHover="hover"
                                variants={starVariants}
                                transition={{ duration: 0.2 }}
                            >
                                <FontAwesomeIcon
                                    icon={faStar}
                                    className={`cursor-pointer ${cleanly_stars >= rating ? 'text-purple-600' : 'text-gray-300'
                                        }`}
                                    style={{ fontSize: '2rem' }}
                                    onClick={() => setCleanlyStars(rating)}
                                />
                            </motion.div>
                        ))}
                    </div>
                    {errors.cleanlyStars && <p className="text-red-500 mt-2">{errors.cleanlyStars}</p>}
                </div>
                <div className="mb-4 text-center">
                    <p className="text-gray-700 mb-2">¿Como fue el trato durante todo el proceso de entrega?</p>
                    <div className="flex justify-center">
                        {[1, 2, 3, 4, 5].map((rating) => (
                            <motion.div
                                key={rating}
                                initial="initial"
                                whileHover="hover"
                                variants={starVariants}
                                transition={{ duration: 0.2 }}
                            >
                                <FontAwesomeIcon
                                    icon={faStar}
                                    className={`cursor-pointer ${expectations_stars >= rating ? 'text-purple-600' : 'text-gray-300'
                                        }`}
                                    style={{ fontSize: '2rem' }}
                                    onClick={() => setexpectationsStars(rating)}
                                />
                            </motion.div>
                        ))}
                    </div>
                    {errors.expectationsStars && <p className="text-red-500 mt-2">{errors.expectationsStars}</p>}
                </div>
                <div className="mb-4 text-center">
                    <p className="text-gray-700 mb-2">¿La entrega fue puntual y adecuada?</p>
                    <div className="flex justify-center">
                        {[1, 2, 3, 4, 5].map((rating) => (
                            <motion.div
                                key={rating}
                                initial="initial"
                                whileHover="hover"
                                variants={starVariants}
                                transition={{ duration: 0.2 }}
                            >
                                <FontAwesomeIcon
                                    icon={faStar}
                                    className={`cursor-pointer ${kindness_stars >= rating ? 'text-purple-600' : 'text-gray-300'
                                        }`}
                                    style={{ fontSize: '2rem' }}
                                    onClick={() => setkindnessStars(rating)}
                                />
                            </motion.div>
                        ))}
                    </div>
                    {errors.kindnessStart && <p className="text-red-500 mt-2">{errors.kindnessStart}</p>}
                </div>
                <div className="mb-6 text-center">
                    <p className="text-gray-700 mb-2">Comentario público</p>
                    <textarea
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        placeholder="Escribe tu comentario público aquí..."
                        value={public_comment}
                        onChange={(e) => setPublic_comment(e.target.value)}
                    />
                    {errors.publicComment && <p className="text-red-500">{errors.publicComment}</p>}
                    <p className="text-gray-700 mb-2 mt-4">Comentario privado</p>
                    <textarea
                        className="w-full p-3 border border-gray-300 rounded-lg "
                        placeholder="Escribe tu comentario privado aquí..."
                        value={private_comment}
                        onChange={(e) => setPrivateComment(e.target.value)}
                    />
                    {errors.privateComment && <p className="text-red-500 mt-2">{errors.privateComment}</p>}
                </div>
                <button
                    className={`w-full py-3 font-semibold rounded-lg ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-purple-600 text-white'
                        }`}
                    onClick={handleEvaluationSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? 'Enviando...' : message || 'Enviar evaluación'}
                </button>
                <div>
                    <button
                        onClick={onClose}
                        className="w-full px-6 py-3 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-lg transition mb-4"
                    >
                        Cancelar
                    </button>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default LessorEvaluation;
