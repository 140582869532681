import {
    faArrowLeft,
    faArrowRight,
    faBan,
    faBath,
    faBed,
    faCar,
    faHome,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BATHROOMS, CHILDRENS, PARKING, PETS, ROOMS } from '../../../constants';
import { ICON_MAP } from '../../../constants/index';
import { getBest, getRentsDurations, getTypesAmenities } from '../../../services/properties.service';
import { __esModule } from 'react-ratings';
import ErrorModal from '../../../components/modals/modal-error';

const Property = ({ step, setStep, setUtilsProperties, utilsProperties, contador, onSubmitPropertyBeforePhotos }) => {
    const { token } = useSelector((state) => state.auth);
    const [amenities, setAmenities] = useState([]);
    const [bestAmenities, setBestAmenities] = useState([]);
    const [rentsDurations, setRentsDurations] = useState([]);
    const [utilsValues, setUtilsValues] = useState(utilsProperties);
    const [isExpanded, setIsExpanded] = useState({ amenities: false });
    const [showMissingModal, setShowMissingModal] = useState(false);
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');

    const toggleExpand = (section) => {
        setIsExpanded({
            ...isExpanded,
            [section]: !isExpanded[section],
        });
    };

    const handleOnPressOneItem = (name, value) => {
        setUtilsValues({
            ...utilsValues,
            [name]: [value],
        });
    };

    const handleOnPressMultipleItem = (name, value) => {
        const prev = utilsValues[name];
        if (prev.includes(value)) {
            setUtilsValues({
                ...utilsValues,
                [name]: prev.filter((v) => v !== value),
            });
        } else {
            setUtilsValues({
                ...utilsValues,
                [name]: [...prev, value],
            });
        }
    };

    const handleCloseModal = () => {
        setShowMissingModal(false);
    }

    const handleContinue = () => {
        console.log(utilsValues);
        const missingFields = [];
        if (!utilsValues.surface) missingFields.push('Superficie');
        if (utilsValues.for_rent.length === 0) missingFields.push('Tipo de renta');
        if (utilsValues.rooms.length === 0) missingFields.push('Recamaras');
        if (utilsValues.bathrooms.length === 0) missingFields.push('Baños');
        if (utilsValues.parking.length === 0) missingFields.push('Estacionamientos');
        if (utilsValues.pets.length === 0) missingFields.push('Mascotas');
        if (utilsValues.childrens.length === 0) missingFields.push('Niños');

        if (missingFields.length > 0) {
            setTitle("Campos requeridos:");
            setText(missingFields.join('/n'));
            setShowMissingModal(true);

        } else {
            confirmProperties();
        }
    }

    const allFieldsCompleted = utilsValues.surface &&
        utilsValues.for_rent.length > 0 &&
        utilsValues.rooms.length > 0 &&
        utilsValues.bathrooms.length > 0 &&
        utilsValues.parking.length > 0 &&
        utilsValues.pets.length > 0 &&
        utilsValues.childrens.length > 0;

    const buttonClasses = `w-full md:w-auto flex items-center justify-center text-white transition duration-300 px-6 py-3 rounded-full shadow-lg ${allFieldsCompleted ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300'}`;

    const confirmProperties = async () => {
        setUtilsProperties(utilsValues);
        console.log(utilsProperties, "vals utils: ", utilsValues)
        onSubmitPropertyBeforePhotos(utilsValues);
        setStep(step + 1);
    };

    const getAllTypeAmenities = async () => {
        const resp = await getTypesAmenities(token);
        if (resp.data.message) {
            setAmenities(resp.data.amenities);
        }
    };

    const getAllBestAmenities = async () => {
        const resp = await getBest(token);
        if (resp.data.message) {
            setBestAmenities(resp.data.bestAmenities);
        }
    };

    const getAllRentDurations = async () => {
        const resp = await getRentsDurations(token);
        console.log(resp.data.durationTypes);

        if (resp.data.message) {
            setRentsDurations(resp.data.durationTypes);
        }
    };

    useEffect(() => {
        getAllTypeAmenities();
        getAllRentDurations();
        getAllBestAmenities();
    }, []);


    const countSelectedItems = (key) => utilsValues[key]?.length || 0;

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center w-full"
        >
            {contador > 0 && (
                <div className="flex items-center justify-center mb-6">
                </div>
            )}
            <h3 className="font-semibold text-xl mb-6">Selecciona las características de tu inmueble</h3>
            <div className="w-full mb-6 flex flex-col md:grid md:grid-cols-1 md:grid-cols-2 gap-2 gap-y-4 md:gap-6">

                <div className="w-full">
                    <label className="text-[15px]">Superficie Construida (m²)</label>
                    <div className="w-full flex items-center gap-x-1 mt-2">
                        <input
                            placeholder="Superficie"
                            className="w-full border-2 border-gray-300 rounded-full shadow-lg text-[#000] text-xs placeholder-[#777] px-4 py-2"
                            onInput={(e) => /^\d*$/.test(e.target.value) && setUtilsValues({ ...utilsValues, surface: e.target.value })}
                            value={utilsValues.surface}
                        />
                        <span className="text-[#000]">m²</span>
                    </div>
                </div>

                <div className="w-full">
                    <label className="text-[15px] flex items-center gap-x-2 mb-2">
                        <FontAwesomeIcon icon={faBed} />
                        Recámaras
                    </label>
                    <div className="flex items-center gap-2">
                        {ROOMS.map((row, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`w-auto px-3 h-8 flex justify-center items-center rounded-full border-2 ${utilsValues.rooms.includes(row.id) ? 'border-azul bg-white' : 'border-gray-300 bg-gray-100'}`}
                                onClick={() => handleOnPressOneItem("rooms", row.id)}
                            >
                                <span className={`text-xs font-bold ${utilsValues.rooms.includes(row.id) ? 'text-azul' : 'text-gray-700'}`}>{row.name}</span>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="w-full">
                    <label className="text-[15px] flex items-center gap-x-2 mb-2">
                        <FontAwesomeIcon icon={faBath} />
                        Baños
                    </label>
                    <div className="flex items-center gap-2">
                        {[...BATHROOMS].map((row, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`w-full px-3 h-8 flex justify-center items-center rounded-full border-2 ${utilsValues.bathrooms.includes(row.id) ? 'border-azul bg-white' : 'border-gray-300 bg-gray-100'}`}
                                onClick={() => handleOnPressOneItem("bathrooms", row.id)}
                            >
                                <span className={`text-xs font-bold ${utilsValues.bathrooms.includes(row.id) ? 'text-azul' : 'text-gray-700'}`}>{row.name || '0'}</span>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="w-full">
                    <label className="text-[15px] flex items-center gap-x-2 mb-2">
                        <FontAwesomeIcon icon={faCar} />
                        Estacionamientos
                    </label>
                    <div className="flex items-center gap-2">
                        {[...PARKING].map((row, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`w-full px-3 h-8 flex justify-center items-center rounded-full border-2 ${utilsValues.parking.includes(row.id) ? 'border-azul bg-white' : 'border-gray-300 bg-gray-100'}`}
                                onClick={() => handleOnPressOneItem("parking", row.id)}
                            >
                                <span className={`text-xs font-bold ${utilsValues.parking.includes(row.id) ? 'text-azul' : 'text-gray-700'}`}>{row.name || '0'}</span>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="w-full col-span-2 rounded-xl border-2 p-4 border-gray-300">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleExpand('amenities')}>
                        <label className="text-[15px]">
                            Amenidades
                        </label>
                        <span className="bg-azul text-white text-sm px-4 py-1 rounded-full">{countSelectedItems('amenities')} seleccionados</span>
                    </div>
                    {isExpanded.amenities && (
                        <div className="w-full mt-4 flex flex-wrap gap-3">
                            {amenities.map(row => {
                                const isSelected = utilsValues.amenities.includes(row.id);
                                const background = isSelected ? "bg-green-500 text-white border-green-500" : "bg-white text-[#777] border-gray-300";
                                return (
                                    <div
                                        key={row.id}
                                        onClick={() => handleOnPressMultipleItem("amenities", row.id)}
                                        className={`border-2 text-xs font-bold flex cursor-pointer items-center justify-center rounded-full px-4 py-2 shadow-md transition-transform transform ${background} hover:scale-105`}
                                    >
                                        <FontAwesomeIcon icon={ICON_MAP[row.icon]} className="mr-2" />
                                        <span>{row.name}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                <div className="w-full col-span-2 rounded-xl border-2 p-4 border-gray-300">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleExpand('best_propertie')}>
                        <label className="text-[15px]">
                            Lo mejor de tu inmueble
                        </label>
                        <span className="bg-azul text-white text-sm px-4 py-1 rounded-full">{countSelectedItems('best_propertie')} seleccionados</span>
                    </div>
                    {isExpanded.best_propertie && (
                        <div className="w-full mt-4 flex flex-wrap gap-3">
                            {bestAmenities.map(row => {
                                const isSelected = utilsValues.best_propertie.includes(row.id);
                                const background = isSelected ? "bg-green-500 text-white border-green-500" : "bg-white text-[#777] border-gray-300";
                                return (
                                    <div
                                        key={row.id}
                                        onClick={() => handleOnPressMultipleItem("best_propertie", row.id)}
                                        className={`border-2 text-xs font-bold flex cursor-pointer items-center justify-center rounded-full px-4 py-2 shadow-md transition-transform transform ${background} hover:scale-105`}
                                    >
                                        <FontAwesomeIcon icon={ICON_MAP[row.icon]} className="mr-2" />
                                        <span>{row.name}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                <div className="w-full">
                    <label className="text-[15px]">Niños</label>
                    <div className="w-full mt-2 flex flex-wrap gap-3">
                        {CHILDRENS.map(row => {
                            const isSelected = utilsValues.childrens.includes(row.id);
                            const background = isSelected ? "bg-green-500 text-white border-green-500" : "bg-white text-[#777] border-gray-300";
                            return (
                                <div
                                    key={row.id}
                                    onClick={() => handleOnPressOneItem("childrens", row.id)}
                                    className={`border-2 text-xs font-bold flex cursor-pointer items-center justify-center rounded-full px-6 py-2 shadow-md transition-transform transform ${background} hover:scale-105`}
                                >
                                    <div className="relative w-6 h-6 mr-2">
                                        <FontAwesomeIcon icon={row.icon} className='text-azul text-xl' />
                                        {!row.isAllowed && (
                                            <FontAwesomeIcon icon={faBan} className="absolute w-full h-full left-[-4px] top-[-1px] text-red-500" />
                                        )}
                                    </div>
                                    <span>{row.name}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="w-full">
                    <label className="text-[15px]">Mascotas</label>
                    <div className="w-full mt-2 flex flex-wrap gap-3">
                        {PETS.map(row => {
                            const isSelected = utilsValues.pets.includes(row.id);
                            const background = isSelected ? "bg-green-500 text-white border-green-500" : "bg-white text-[#777] border-gray-300";
                            return (
                                <div
                                    key={row.id}
                                    onClick={() => handleOnPressOneItem("pets", row.id)}
                                    className={`border-2 text-xs font-bold flex cursor-pointer items-center justify-center rounded-full px-6 py-2 shadow-md transition-transform transform ${background} hover:scale-105`}
                                >
                                    <div className="relative w-6 h-6 mr-2">
                                        <FontAwesomeIcon icon={row.icon} className='text-azul text-xl' />
                                        {!row.isAllowed && (
                                            <FontAwesomeIcon icon={faBan} className="absolute w-full h-full left-[-3px] top-[-1px] text-red-500" />
                                        )}
                                    </div>
                                    <span>{row.name}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="w-full">
                    <label className="text-[15px]">Se puede rentar por</label>
                    <div className="w-full mt-2 flex flex-wrap gap-3">
                        {/* Renta Vacacional */}
                        <div className="w-full">
                            <h4 className="text-xs font-medium text-gray-800 flex items-center gap-2">
                                <FontAwesomeIcon icon={ICON_MAP['fa-duotone fa-wave']} /> Renta Vacacional
                            </h4>
                            <div className="flex gap-2 mt-2">
                                {rentsDurations.filter(row => !row.is_long_stay).map(row => {
                                    const isSelected = utilsValues.for_rent.includes(row.id);
                                    const background = isSelected ? "bg-green-500 text-white border-green-500" : "bg-white text-[#777] border-gray-300";
                                    return (
                                        <div
                                            key={row.id}
                                            onClick={() => handleOnPressMultipleItem("for_rent", row.id)}
                                            className={`border-2 text-xs font-bold flex cursor-pointer items-center justify-center rounded-full px-6 py-2 shadow-md transition-transform transform ${background} hover:scale-105`}
                                        >
                                            <span>{row.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/* Renta */}
                        <div className="w-full mt-4">
                            <h4 className="text-xs font-medium text-gray-800 flex items-center gap-2">
                                <FontAwesomeIcon icon={faHome} />
                                Renta
                            </h4>
                            <div className="flex gap-2 mt-2">
                                {rentsDurations.filter(row => row.is_long_stay).map(row => {
                                    const isSelected = utilsValues.for_rent.includes(row.id);
                                    const background = isSelected ? "bg-green-500 text-white border-green-500" : "bg-white text-[#777] border-gray-300";
                                    return (
                                        <div
                                            key={row.id}
                                            onClick={() => handleOnPressMultipleItem("for_rent", row.id)}
                                            className={`border-2 text-xs font-bold flex cursor-pointer items-center justify-center rounded-full px-6 py-2 shadow-md transition-transform transform ${background} hover:scale-105`}
                                        >
                                            <span>{row.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-2 flex flex-col-reverse md:flex-row justify-between items-center gap-4 mt-6">
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="w-full md:w-auto flex items-center justify-center text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full shadow-lg"
                        onClick={() => setStep(step - 1)}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Regresar
                    </motion.button>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={buttonClasses}
                        onClick={handleContinue}
                    >
                        Continuar
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </motion.button>
                </div>

            </div>
            <ErrorModal show={showMissingModal} text={text} title={title} onClose={handleCloseModal} />
        </motion.div>
    );
};

export default Property;
