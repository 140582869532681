import { motion } from 'framer-motion';
import React from 'react';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/header/Navbar';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5
        }
    }
};

const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut"
        }
    }
};

const AvisoPrivacidad = () => {


    return (
        <>
            <Navbar />
            <div className="bg-gray-100 py-[2rem] text-gray-900">
                <div className="bg-white rounded-lg container mx-auto text-gray-900">
                    <div className="container mx-auto py-12 p-6 lg:px-[6rem] xl:px-[15rem]">
                        <h1 className="text-3xl font-bold text-center mb-6">TÉRMINOS Y CONDICIONES GENERALES</h1>
                        <motion.div
                            className="space-y-6"
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                        >
                            <motion.section variants={itemVariants}>
                                <>TÉRMINOS Y CONDICIONES GENERALES
SWMITH, S.A.P.I DE C.V (en lo sucesivo mejor conocido como “Yo Rento”), con
domicilio en calle Avenida Américas #1930, piso 2, interior 7, colonia Country Club,
en la ciudad de Guadalajara, Jalisco, México, C.P 44610, hace de su conocimiento
los términos y condiciones generales (en lo sucesivo “Términos y Condiciones
Generales”) que regulan los servicios que ofrece a través de su portal de internet
www.yorento.com y la aplicación móvil correspondiente (en lo sucesivo y de manera
conjunta la “Plataforma”).
ACEPTACIÓN DEL USUARIO
Los Servicios de Yo Rento están dirigidos a personas físicas o jurídicas con
capacidad legal para contratar. Cualquier persona, al utilizar la Plataforma tendrá la
condición de usuario (en lo sucesivo “Usuario”).
Los Usuarios que publican Anuncios de inmuebles serán “Arrendadores”, mientras
que los miembros que buscan o utilizan servicios son “Arrendatarios”.
No podrán utilizar los Servicios las personas que no tengan esa capacidad, los
menores de edad o de los Usuarios que hayan sido suspendidos de la Plataforma.
Al utilizar y navegar por la Plataforma y/o al registrarse como Usuario usted declara
con carácter de declaración bajo protesta legal de decir verdad que: i) es una
persona física mayor de 18 años cumplidos, con capacidad jurídica para contratar;
o que ii) es el representante de una persona jurídica con las facultades suficientes
para contratar en nombre de dicha persona jurídica.
Estos Términos y Condiciones Generales constituyen un contrato vinculante entre
los Usuarios y Yo Rento, por lo que es importante que los lea con detenimiento antes
de aceptarlos.
Para todos los efectos legales, se entiende que el Usuario ha leído y acepta, sin
limitación ni condición alguna, los presentes Términos y Condiciones Generales al
acceder, navegar, y utilizar cualquier componente de la Plataforma, utilizar sus
herramientas, funciones o servicios, registrarse como miembro, o consultar
cualquier textom gráfico o video mediante cualquier equipo tecnológico, sea de
cómputo, teléfono móvil, tableta o cualquier otro similar o análogo.
Si el Usuario no está de acuerdo con estos Términos y Condiciones Generales y,
por ende, no desea obligarse conforme a los mismos, debe abstenerse de acceder
a la Plataforma y utilizar cualesquiera de los Servicios. Lo anterior en el entendido 
de que el abandono de la Plataforma por parte del Usuario no implicará la liberación
respecto de las obligaciones previamente adquiridas de conformidad con lo
dispuesto en los presentes Términos y Condiciones Generales.
Yo Rento se reserva el derecho de suspender, cancelar o impedir el uso de la
Plataforma a quienes no cumplan con los términos y condiciones descritos en este
documento.
SERVICIOS
Yo Rento tiene por objeto diseñar, desarrollar, actualizar, comercializar y/u operar
todo tipo de sistemas de programas de cómputo (software), aplicaciones para
distintas plataformas y sistemas operativos, propios o de terceros, así como
mantener una base de datos de los usuarios de la plataforma, que le permiten actuar
en la intermediación inmobiliaria de alquileres.
En virtud de lo anterior, los Servicios ofrecidos a través de la Plataforma
comprenden: oferta de propiedades para arrendamiento; búsqueda de propiedades;
servicios de comunicación entre potenciales inquilinos y/o arrendadores de
inmuebles; asesoría y acompañamiento para la renta de inmuebles; servicios de
investigación integral de potenciales inquilinos; así como membresías que permiten
la colocación de uno o varios anuncios en formato previamente diseñado por la
plataforma (en lo sucesivo los “Servicios”).
De manera particular se ofrecen dichos Servicios a través de las siguientes
modalidades:
1. Publicación básica: Publicación única con una duración de 30 días.
2. Publicación destacada: Anuncio señalado como publicación destacada
por 30 días.
3. Confía en lo que ves: Servicio de validación de anuncio de propiedad
con duración de 1 año, en el cual Yo Rento realiza la convalidación de las
características del inmueble anunciado, de manera enunciativa mas no
limitativa, las siguientes:
• Exactitud del domicilio;
• Existencia de las amenidades publicadas;
• Descripción del inmueble.
4. PDF white label: autorización de la Plataforma para la descarga en
formato PDF de las publicaciones de inmuebles realizadas en la
Plataforma con las características, fotografías y descripciones originales,
sin ningún tipo de logotipo, nombre, ni marca de agua referente a Yo
Rento, siempre y cuando dicha publicación haya sido etiquetada como
propiedad que comparte comisión al momento de su publicación.
5. Membresía plus: Membresía mensual para publicar propiedades de
larga estancia con una duración de 30 días. Incluye el beneficio del Coach
Yo Rento (Chatbot).
6. Membresía pro: Membresía mensual para publicar propiedades de corta
estancia con límite de 10 publicaciones. Incluye el beneficio de poder
utilizar el Coach Yo Rento (Chatbot).
7. Membresía pro max: Membresía mensual para publicar propiedades de
corta estancia con límite de 30 publicaciones con una duración de 30 días.
Incluye el beneficio del Coach Yo Rento (Chatbot).
8. Membresía premium: Membresía mensual para publicar propiedades
ilimitadas de corta y larga estancia con una duración de 30 días. Incluye
el beneficio de poder utilizar el Coach Yo Rento (Chatbot).
Cada uno de los Servicios ofrecidos podrán estar sujetos a términos y condiciones
particulares complementarias a las establecidas en los presentes Términos y
Condiciones Generales, los cuales deberán ser aceptadas por el Usuario previo a
su uso. Las condiciones particulares prevalecerán sobre los presentes Términos y
Condiciones Generales en caso de conflicto.
USUARIOS NO REGISTRADOS
Los Usuarios no registrados únicamente podrán consultar el catálogo de inmuebles
que figuren en el apartado de Anuncios de la Plataforma.
REGISTRO DE USUARIOS
Los Usuarios deberán crear una cuenta de Yo Rento y registrarse en la Plataforma
con el requisito obligatorio de validar una cuenta de correo electrónico y un número
de teléfono celular para acceder a los servicios que presta la Plataforma y en su
caso cubrir el precio correspondiente.
El llenado de los formularios de registro en la Plataforma se someterá a las
siguientes reglas:
• El Usuario se obliga a proporcionar información verídica, actualizada,
completa y precisa.
• La información y datos proporcionados por el Usuario se regirá por lo
establecido en el Aviso de Privacidad.
• El Usuario se obliga a actualizar su información, de manera inmediata, ante
la ocurrencia de algún cambio en la misma o ante el advertimiento de algún
error al momento del registro, para efectos de que dicha información
permanezca actualizada, auténtica, precisa y completa.
Yo Rento se reserva el derecho de suspender, cancelar y/o impedir el uso presente
o futuro de la Plataforma, de los Servicios o cualquier parte de los mismos, cuando
se verifique que la información suministrada por el Usuario es falsa, inexacta,
desactualizada, incompleta o cuando Yo Rento tenga motivos suficientes y
razonables para sospechar que dicha información es falsa, inexacta, desactualizada
o incompleta.
MANEJO DE CONTRASEÑA
El Usuario registrado se obliga a mantener su contraseña en secreto. No deberá
compartir su contraseña o el acceso a su cuenta con ninguna otra persona y
respetará las normas de Yo Rento y la legislación aplicable.
Al momento de registrarse en la Plataforma el Usuario se obliga a:
• Elegir una contraseña segura;
• Mantener la confidencialidad de la contraseña;
• Actualizar y cambiar periódicamente, con una frecuencia razonable, la
contraseña;
• Notificar a Yo Rento de manera inmediata de cualquier uso indebido o
sospecha de uso indebido de su cuenta o cualquier otra vulneración de
seguridad.
El Usuario registrado es responsable del uso y todo lo que suceda en su cuenta,
salvo que cierre su sesión o notifique de manera previa y oportuna que se esté
realizando un uso indebido de la misma.
PROHIBICIONES
De manera enunciativa y no limitativa, el Usuario se compromete de manera
expresa a no realizar los siguientes actos y/o actividades al hacer uso de nuestra
Plataforma:
a) A ceder o transferir de otro modo el acceso como Usuario a cualquier otra
persona o entidad;
b) Hacer un uso no autorizado o fraudulento de la Plataforma;
c) Acceder o intentar acceder a recursos restringidos de la Plataforma;
d) Utilizar la Plataforma con fines ilícitos, ilegales, contrarios a lo establecido
en los presentes Términos y Condiciones Generales;
e) Provocar daños en la Plataforma o en los sistemas de sus proveedores o
de terceros;
f) Causar o lanzar cualquier programa o script con el objeto de extraer,
indexar, analizar o de otro modo realizar prospección de datos de cualquier
parte de los Servicios o sobrecargar o bloquear indebidamente la operación
y/o funcionalidad de cualquier aspecto de los Servicios;
g) Intentar obtener un acceso no autorizado o dañar cualquier aspecto de la
Plataforma, Servicios o sus sistemas o redes relacionadas;
h) Intentar acceder, utilizar y/o manipular los datos de Yo Rento y/o sus
Usuarios
i) No introducir o difundir contenidos o propaganda de carácter racista,
xenófobo o, en general, discriminatorio, pornográfico, de apología del delito
o que atenten, vulneren o pudieren atentar o vulnerar los derechos humanos;
j) Introducir o difundir en la red programas de datos (virus y/o software nocivo)
susceptibles a provocar daños en los sistemas informáticos de Yo Rento, sus
proveedores, terceros o, en general, cualquier usuario de la red Internet;
k) Difundir, transmitir o poner a disposición de terceros cualquier tipo de
información, elemento o contenido que atente contra los derechos
fundamentales y las libertades públicas reconocidos constitucionalmente y
en los tratados internacionales;
l) Difundir, transmitir o poner a disposición de terceros cualquier tipo de
información, elemento o contenido que constituya publicidad engañosa, ilícita
o desleal;
m) Transmitir publicidad no solicitada o autorizada, material publicitario, "correo
basura o spam", "cartas en cadena", "estructuras piramidales", o cualquier
otra forma de solicitación, excepto en aquellas áreas (tales como espacios
comerciales) que hayan sido exclusivamente concebidas para ello mediante
una comunicación expresa de Yo Rento, comunicada debida Y
oportunamente en la Plataforma y habiendo cubierto previamente la
contraprestación que, en su caso, se haya establecido;
n) Introducir o difundir cualquier información y contenidos falsos, ambiguos o
inexactos de forma que induzca a error a los receptores de la información;
o) Difundir, transmitir o poner a disposición de terceros cualquier tipo de
información, elemento o contenido que suponga una violación de los
derechos de propiedad intelectual e industrial, patentes, marcas o derechos
de autor que correspondan a Yo Rento o a terceros;
p) Difundir, transmitir o poner a disposición de terceros cualquier tipo de
información, elemento o contenido que suponga una violación del secreto de
las comunicaciones y la legislación de datos de carácter personal y, en
general, todas las normas jurídicas que regulen la protección y promoción del
respeto a la vida privada e intimidad de las personas y sus familias.
El Usuario se obliga a sacar en paz y a salvo, o en su caso a indemnizar a Yo Rento
ante cualquier posible reclamación, multa, pena, sanción o indemnización de
cualquier naturaleza, derivada del incumplimiento por parte del Usuario de
cualquiera de las normas de uso antes indicadas, reservándose, además, Yo Rento
el derecho a reclamar la indemnización por daños y perjuicios que corresponda.
En el caso de que Yo Rento realice cualquier erogación con motivo de
reclamaciones formuladas por terceros, derivadas de un uso indebido de la
Plataforma por parte del Usuario, Yo Rento se subrogará en los derechos de la parte
reclamante o, en su caso, podrá repetir en contra del Usuario.
TERMINACIÓN
El Usuario puede suspender su participación y el acceso a la Plataforma en
cualquier momento.
El Usuario reconoce y acepta que Yo Rento puede suspender, cancelar y/o impedir
el uso presente o futuro de la Plataforma, los Servicios o cualquier parte de los
mismos, sin previo aviso y por cualquier razón con la única obligación de notificarlo
a través de medios virtuales o tecnológicos.
El Usuario acepta, al cese o interrupción por cualquier razón, Yo Rento puede
eliminar toda la información relacionada con el Usuario y puede impedir el acceso a
la Plataforma y la utilización de sus Servicios.
ANUNCIOS DE INMUEBLES
Para crear y publicar un Anuncio como Arrendador, al Usuario se le solicitará
información sobre el inmueble que pretende arrendar, entre la cual se encuentra, de 
manera enunciativa mas no limitativa, ubicación, características, fotografías, precio,
normas y condiciones de contratación.
Una vez publicado el Anuncio, cualquier persona podrá tener acceso en la
Plataforma.
Cuando el inmueble respectivo sea arrendado o no se encuentre disponible para
arrendar por cualquier motivo el Usuario que haya publicado como Arrendador se
obliga a marcarlo como rentado o remover el anuncio respectivo de la Plataforma/
informar a Yo Rento para efectos de que remueva el Anuncio correspondiente.
La información relativa a la publicación de los inmuebles permanecerá almacenada
en la Plataforma por un período de 5 años desde el momento de su publicación,
mientras tengan el estatus de Visible, Rentada y de Borrador para que pueda ser
utilizada en futuros anuncios por los Usuarios registrados como Arrendadores, salvo
que estos opten por su eliminación.
Al publicar un Anuncio como Arrendador, el Usuario garantiza que:
• La información proporcionada sobre el inmueble es verídica, actualizada,
completa y precisa;
• Utilizará los servicios de Yo Rento en cumplimiento a la legislación aplicable
y no viola los derechos de terceros u obligaciones a que esté sujeto el
inmueble o el Usuario.
• Cuenta con la libre disposición del inmueble o, en su caso, está autorizado
en términos de la legislación civil aplicable para arrendar el inmueble objeto
del Anuncio;
• El inmueble objeto del Anuncio está libre de cualquier tipo de limitación a la
disposición del mismo.
• El inmueble objeto del Anuncio satisface la regulación aplicable en materia
de seguridad, higiene y protección civil; y
• La información que reciba de otros Usuarios, señalando de manera
enunciativa y no limitativa, información crediticia, información financiera, y
demás, serán única y exclusivamente empleadas para su valoración como
potencial arrendatario y su tratamiento estará limitado exclusivamente en
relación con la potencial celebración de un contrato de arrendamiento,
cumpliendo a su vez con lo establecido en la Ley Federal de Protección de
Datos en Posesión de Particulares.
Yo Rento no tiene control del contenido de ninguno de los Anuncios, ni el estado,
situación jurídica o idoneidad de ningún inmueble anunciado, ni es propietario,
poseedor, depositario, administrador ni tenedor de los inmuebles ofrecidos, por lo
que no es responsable por la existencia, calidad, cantidad, estado, o integridad de 
los inmuebles ofrecidos o arrendados por los Usuarios, ni por la exactitud,
veracidad, exhaustividad y actualidad de la información contenida en los Anuncios
publicados, ni de la capacidad de los Arrendadores para contratar. En virtud de lo
anterior, Yo Rento no asume responsabilidad alguna en relación con los Anuncios
publicados y su contenido
El sistema de búsqueda provisto por Yo Rento deberá utilizarse sólo como una guía
complementaria, debiendo el Usuario en todos los casos cerciorarse personalmente
de que el inmueble cumple con sus expectativas.
Yo Rento comercializa diversos servicios para optimizar el acercamiento entre
potenciales arrendadores y arrendatarios, así como ofrece herramientas que
permiten la evaluación de estos últimos, en caso de que dicho servicio específico
sea contratado, por lo que no tiene injerencia alguna en la elaboración, revisión,
negociación o formalización de cualquier contrato de arrendamiento que al efecto
celebren sus Usuarios, por lo que el contenido, alcance, condiciones, términos,
obligaciones, derechos y renuncias de derechos consignados en tales instrumentos
quedan al arbitrio de las partes contratantes, eximiendo en este acto a Yo Rento de
cualquier responsabilidad que pudiera derivar del incumplimiento de alguna de las
partes.
De manera expresa, el Usuario reconoce que las evaluaciónes que se ofrecen en
esta Plataforma de potenciales arrendatarios no deben entenderse como una
garantía de solvencia económica o de pago de cualquier obligación que asuma al
amparo de un contrato de arrendamiento celebrado con otros Usuarios de la
Plataforma.
Las partes liberan a Yo Rento de cualquier obligación que legalmente corresponda
al arrendador, arrendatario o cualquier tipo de garante. Cada Usuario reconoce que
al realizar operaciones con otros Usuarios lo hace bajo su propio riesgo.
De igual manera, se hace constar que Yo Rento no tiene injerencia directa en la
fijación de rentas, ni en el cobro de las mismas por parte de los Usuarios que
publiquen Anuncios como Arrendador, en caso de concretar la celebración de
contratos de arrendamiento.
Asimismo, Yo Rento no cobra comisión alguna sobre el monto de rentas cobradas,
ni realiza retenciones de impuestos o cualquier otro concepto.
INVESTIGACIÓN DE PROFUNDIDAD Y PASA-GURO
Yo Rento elaborará con base en la información proporcionada por el Arrendatario,
así como a través de registros públicos, instituciones gubernamentales, Buró de
Crédito o terceros reportes de evaluación de potenciales inquilinos.
Dichos reportes pueden ser:
1) Investigación de Profundidad:
a) Validación de identidad, mediante identificación y comprobante de domicilio;
b) Cumplimiento de obligaciones fiscales;
c) Buró de crédito;
d) Validación de referencias personales;
e) Historial de cumplimiento de arrendamientos previos (en caso de contar con
información disponible en la Plataforma);
f) Comprobantes de ingresos; y
g) Entrevista personal.
2) Cita segura:
a) Validación de identidad, mediante identificación oficial y biométrico facial
(prueba de vida)
3) Pase-guro:
a) Emisión de documento que certifica la validez de identidad, mediante
identificación oficial y perfilamiento de Usuario, para emplearse en distintas
contrataciones de corta estadía.
El reporte de evaluación exclusivamente tiene por objeto que el Arrendador tenga
un mayor conocimiento del Arrendatario y, en su caso, analizar su capacidad de
pago y cumplimiento de obligaciones, para así determinar la conveniencia de
celebrar un contrato con este.
Los reportes de evaluación elaborados por Yo Rento serán compartidos únicamente
con el Arrendatario, pudiendo este determinar si es su deseo proporcionarlos al
Arrendador con quien pretenda celebrar un acto jurídico.
En virtud de los presentes Términos y Condiciones, el Arrendatario autoriza a Yo
Rento la consulta de su información, incluyendo su información financiera y
crediticia ante las Sociedades de Información Crediticia mediante medios
electrónicos autorizados por dichas entidades.
Los reportes de evaluación no constituyen ningún tipo de garantía por parte de Yo
Rento sobre la identificación de cualquier conducta inapropiada del Arrendatario en
el pasado, ni tampoco que este no incurrirá en conducta inapropiada, iliquidez o
insolvencia en el futuro. 
CALIFICACIÓN DE USUARIOS
Una vez celebrado un contrato de arrendamiento derivado de los Servicios, los
Usuarios tendrán la oportunidad de calificar su experiencia con el Arrendador /
Arrendatario que hubiesen contratado y dejar comentarios adicionales. Los
Usuarios sobre quienes se hayan realizado comentarios tendrán la oportunidad de
una única réplica.
Yo Rento implementará un sistema automatizado de calificación de Usuarios
basado en las siguientes métricas:
Evaluación de Arrendador: a) Cumplimiento de obligaciones contractuales; b)
Condiciones del inmueble; c) Entrega en tiempo y forma; y d) Trato y amabilidad.
Evaluación de Arrendatario: a) Responsabilidad como huésped; b) Trato y
amabilidad; c) Puntualidad en pagos; y d) Cumplimiento de obligaciones
contractuales.
Lo anterior, con base en la información proporcionada por el resto de Usuarios con
quienes se perfeccionen contratos de arrendamiento.
Las evaluaciones arrojarán un resultado global para cada Usuario reflejada en un
rango de 1 a 5, que se muestra a través de un semáforo.
Los Usuarios aceptan el sistema de evaluación y publicación de su calificación
desde el momento en que registran como Usuarios de la Plataforma.
Dicha calificación no implica una evaluación integral de Yo Rento sobre el
comportamiento de los Usuarios, ni una opinión o valoración de esta sobre el
cumplimiento de sus obligaciones.
SUSPENSIÓN DE LA PLATAFORMA
Yo Rento se reserva el derecho a modificar o interrumpir, en cualquier momento, de
manera temporal o permanentemente, la Plataforma, los Servicios o cualquier parte
de los mismos, con la única obligación de notificarlo a través de medios virtuales o
tecnológicos, pudiendo realizarse a través de la misma Plataforma.
El Usuario acepta que Yo Rento no será responsable ante el Usuario ni ante ningún
tercero por ninguna modificación, suspensión o interrupción de las actividades de la
Plataforma. 
SUSPENSIÓN DE USUARIOS POR REPORTES
Yo Rento se reserva el derecho a suspender temporal o permanentemente el uso
de la Plataforma a cualquier Usuario que reciba reportes suficientes de manera que
Yo Rento estime pertinente una intervención por mal uso del chat interno de la
Plataforma cuando el contenido se considere de manera enunciativa mas no
limitativa:
• Inapropiado;
• No relacionado con la propiedad;
• Discurso de odio;
• Acoso o intimidación
• Spam.
SUSPENSIÓN DE PUBLICACIONES
Yo Rento se reserva el derecho a suspender temporal o permanentemente los
anuncios publicados en la Plataforma que reciban reportes suficientes de manera
que Yo Rento estime pertinente una intervención cuando en dicha publicaciones se
consideren de manera enunciativa mas no limitativa la existencia de una o varias de
las siguientes circunstancias:
• Inmueble no disponible;
• El Arrendador no acepta solicitudes de chat;
• Publicidad engañosa.
NO DISCRIMINACIÓN
Cualquier Usuario podrá acceder a la Plataforma y a los Servicios; en Yo Rento no
negaremos, condicionaremos, ni estableceremos preferencias por razones de
género, nacionalidad, étnicas, preferencias sexuales, religiosas o cualquier otro
motivo que se traduzca en discriminación.
De esta manera, no se llevarán a cabo prácticas de selección de clientela, exclusión
de personas con discapacidad u otras prácticas similares, salvo por causas que
afecten la seguridad o tranquilidad de la Plataforma y/o la prestación de los
Servicios, de otros Usuarios o de las personas discapacitadas, o se funden en
disposiciones expresas de otros ordenamientos legales.
ENLACES
La Plataforma puede contener enlaces a sitios web de terceros o publicidad de
terceros. Yo Rento no se hace responsable de su contenido, ni de la disponibilidad
o exactitud de los mismos. Los enlaces en la Plataforma no implican ningún respaldo
por parte de Yo Rento a los sitios web o respecto de los productos o servicios
anunciados, ni su contenido. El Usuario asume bajo su propio riesgo el acceso a
dichos sitios web o del uso de los productos o servicios ofertados.
Algunas partes de la Plataforma funcionan con servicios de terceros como lo son
Facebook, X, Linkedin, Círculo de Crédito, Google, entre otros.
PRIVACIDAD Y COOKIES
Yo Rento utilizará los datos e información proporcionada por el Usuario de
conformidad con su política de protección de datos personales, la cual puede ser
consultada en el Aviso de Privacidad.
De igual manera, se hace del conocimiento del Usuario que nuestra Plataforma
puede utilizar cookies, web beacons y otras teconologías, a través de las cuales es
posible monitorear su comportamiento como usuario de internet, brindarle un mejor
servicio y experiencia de usuario, así como ofrecerle nuevos productos y servicios
basados en su preferencia. Para conocer más sobre ello o cómo limitarlas consulte
nuestro Aviso de Privacidad.
PROPIEDAD INTELECTUAL
El Usuario reconoce que los derechos de propiedad intelectual de la Plataforma, su
contenido e información son propiedad exclusiva de Yo Rento o sus afiliadas, y
quedan expresamente reservados todos los derechos sobre los mismos, por lo que
se prohíbe expresamente su uso total o parcial sin su autorización expresa, no
estando permitida la reproducción total ni parcial de los mismos, salvo autorización
expresa y por escrito previo.
Usted se compromete a no retirar, alterar ni ocultar avisos de derechos de autor,
marcas comerciales, marcas de servicio u otros derechos de propiedad que vayan
incorporados o que acompañen a la Plataforma.
Todas las marcas, logotipos, avisos comerciales y cualquier otra designación de
propiedad de Yo Rento utilizados en la Plataforma son propiedad industrial
propiedad de Yo Rento o sus afiliadas.
En el caso de marcas, logotipos, avisos comerciales y cualesquiera otras
designaciones propiedad de terceros utilizados en relación con la Plataforma se 
utilizan solamente para fines de identificación y son propiedad de sus respectivos
propietarios.
El Usuario no adquiere ningún derecho de propiedad intelectual por el simple uso
de los servicios y contenidos de la Plataforma, y en ningún momento dicho uso será
considerado como una autorización o licencia de los derechos de propiedad
intelectual. Razón por la cual, se prohíbe el uso de estos para fines distintos a los
que se contemplan en los presentes Términos y Condiciones Generales.
Cualquier uso no autorizado de los contenidos constituirá una violación del presente
documento y a las normas nacionales e internacionales sobre propiedad intelectual,
y a cualquier otra que sea aplicable.
En caso de encontrar información que pudiera ser violatoria de derechos de
propiedad intelectual, tendrá que ponerse en contacto con nosotros a través de la
cuenta de correo electrónico contacto@yorento.com para poder tomarlas medidas
necesarias para el cumplimiento de las leyes y normas aplicables.
PRECIOS
El costo de los Servicios será el mostrado en la Plataforma al momento de realizar
la contratación.
Las membresías y precios por los Servicios podrán sufrir modificaciones. Yo Rento
le notificará en caso de que tenga membresías vigentes con al menos 30 días de
anticipación a que los cambios surtan efectos. Si el usuario no desea aceptar la
modificación de precio o a su membresía, podrá cancelar los Servicios contratados
en forma previa a que surta efectos la modificación.
PAGOS
Yo Rento utilizará la plataforma y pasarela de pagos Stripe, Inc. (en lo sucesivo
“Stripe”) para realizar el cobro de los Servicios y membresías que ofrece al Usuario.
La Plataforma Stripe procesará el pago, y en caso de requerir autenticación
adicional (3D secure), el Usuario deberá realizarla a través de su banco.
El cobro de las membresías mensuales se realizará de manera automática mes tras
mes, sin embargo, el Usuario podrá solicitar la cancelación de su membresía hasta
un día antes de que se realice el cobro mensual, de tal manera que no se renovará
su membresía ni se realizará el cargo a su tarjeta. 
Una vez que haya sido realizado el pago mensual se podrá proceder a la
cancelación en cualquier momento del mes, pero la cantidad pagada no será
reembolsable y gozará de los beneficios de su membresía hasta el término de la
mensualidad ya pagada.
Yo Rento no se hace responsable por fallas o problemas en la plataforma de pagos
Stripe, y en su caso el Usuario deberá comunicarse directamente con su servicio de
atención a clientes en https://stripe.com/mx.
PERÍODO DE PRUEBA GRATIS
Yo Rento podrá ofrecer al Usuario un período de prueba gratis. Sin embargo, la
Plataforma se reserva el derecho de elección de dicho período, así como las
condiciones del mismo.
Al terminar el período de prueba gratis Yo Rento procederá a realizar el cargo
correspondiente mediante la plataforma de pagos Stripe,Inc. de forma automática
mes tras mes, a menos de que el Usuario cancele la suscripción previo a que
termine el período de prueba gratuito.
FACTURACIÓN
En caso de que el Usuario requiera factura, será necesario solicitarla al correo
electrónico contacto@yorento.com, acompañando la siguiente información y/o
documentos:
• Constancia de situación fiscal;
• Teléfono;
• Correo; y
• Uso de CFDI.
INTELIGENCIA ARTIFICIAL EN CHAT BOT
Las recomendaciones, respuestas o sugerencias proporcionadas por la Inteligencia
Artificial en el Chat Bot de la Plataforma son generadas automáticamente y no
deben ser consideradas como asesoramiento profesional. La Inteligencia Artificial
puede cometer errores y su uso es bajo la responsabilidad del Usuario.
La aplicación no será responsable por daños directos, indirectos o consecuentes
que surjan del uso de las respuestas o recomendaciones de la Inteligencia Artificial. 
Se aconseja al Usuario que verifique por sus propios medios cualquier información
proporcionada por la Inteligencia Artificial.
LEYES Y JURISDICCIÓN
Yo Rento opera la Plataforma y todas sus actividades desde México y no garantiza
que el contenido es apropiado o disponible para su uso en otros lugares. Si usted
tiene acceso a la Plataforma desde un lugar ubicado fuera del territorio mexicano,
lo hace bajo su propio riesgo y es responsable del cumplimiento de las leyes
aplicables en su jurisdicción, renunciando a las leyes de la jurisdicción de su
domicilio o nacionalidad.
Absténgase de utilizar la Plataforma si tal conducta infringe el ordenamiento jurídico
del país donde usted reside o utiliza la Plataforma.
Los presentes Términos y Condiciones se regirán e interpretarán de conformidad
con las leyes de los Estados Unidos Mexicanos, independientemente de las
disposiciones relacionadas con conflictos de leyes. Cualquier procedimiento de
carácter legal que se derive o se relacione con este Contrato será dirimido en los
tribunales competentes de la zona metropolitana de Guadalajara, Jalisco,
renunciando expresamente al fuero que pudiese corresponderle por razón de su
domicilio presente o futuro.
Si alguna previsón de estos Términos y Condiciones Generales, es declarada nula,
inválida o ineficaz, ello no afectará la validez de las previsiones restantes.
MODIFICACIONES
Yo Rento podrá modificar los Términos y Condiciones Generales, o cualquier
término adicional que se aplique a algún Servicio específico. Yo Rento podrá a
disposición, cuando ello fuere posible, un aviso de las modificaciones de los
términos en la Plataforma. Los cambios a los Términos y Condiciones Generales
entrarán en vigor a los 10 días naturales de su publicación en la Plataforma.
Al momento de registarse en la Plataforma el Usuario manifiesta haber leído,
comprendido y aceptado expresamente los presentes Términos y Condiciones, sin
que medie algún vicio de la voluntad.
LIMITACIÓN DE RESPONSABILIDAD
El Usuario acepta que el uso de la Plataforma se realiza bajo su propia, exclusiva y
única responsabilidad.
Yo Rento no asume responsabilidad alguna que derive del intercambio de
información electrónica entre sus Usuarios, ni derivado de los actos jurídicos que
estos celebren o dejen de celebrar o cualquier hecho jurídico relacionado con el uso
de la Plataforma.
Yo Rento no se responsabiliza por ningún daño o perjuicio, directo o indirecto,
incluyendo sin ningún tipo de limitación daños producidos por pérdidas o deterioros
de la información, ni por las demoras, interrupciones y defectos que pudieran existir
en las transmisiones, mediante la utilización de la Plataforma, así como tampoco de
los virus o fallas del sistema, siempre que ello no sea imputable a Yo Rento. Ni Yo
Rento, ni sus accionistas, directores, funcionarios, empleados, afiliados o agentes
garantizan que la Plataforma estará libre de interrupciones o errores; tampoco hace
ninguna garantía en cuanto a los resultados que se pueden obtener del uso de la
misma o en cuanto a la exactitud, confiabilidad, adecuación o contenido de la
información que aparece en la Plataforma.
Yo Rento no será responsable frente al Usuario ni frente ningún tercero por los
daños, infracciones, delitos, contravenciones o cualquier otra clase de ilicitos que
pudieran cometerse, ya se trate de derechos de propiedad intelectual, al honor y
reputación de las personas, sus datos personales, crediticios, derechos del
consumidor, ni cualquier otro derecho que pudiera ser vulnerado como
consecuencias del mal uso de la Plataforma por parte de los Usuarios.
El Usuario se obliga en forma expresa e irrevocable a mantener absolutamente libre
de responsabilidad a Yo Rento, así como a sus accionistas, directores, funcionarios,
empleados, afiliados o agentes, respecto a cualquier reclamo y/o demanda y/o
sanción, administrativa o judicial, iniciado por otros Usuarios, terceros o por
cualquier Organismo relacionado con sus actividades en la Plataforma, el
cumplimiento o y/o incumplimiento de estos Términos y Condiciones Generales, o
en caso de verificarse la existencia de multas lo deudas de cualquier tipo generadas
por el Usuario o la actividad del mismo realizada en o mediante la Plataforma.</>
                            </motion.section>
                            {/* Puedes añadir más secciones aquí */}
                        </motion.div>
                    </div>
                </div>
            </div>

            <Footer />

        </>

    );
};

export default AvisoPrivacidad;
