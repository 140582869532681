import {
  faArrowLeft,
  faBaby,
  faBan,
  faBedFront,
  faCrown,
  faEdit,
  faGarageCar,
  faHeart,
  faHouse,
  faImages,
  faInfoCircle,
  faMoon,
  faPaw,
  faShareNodes,
  faShower,
  faSun,
  faSealExclamation,
  faFileCertificate
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { connect } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useNavigate } from 'react-router-dom'
import MapaDetallePropiedad from '../../components/map/MapaDetallePropiedad'
import { ICON_MAP } from '../../constants/index'
import { AuthContext } from '../../provider/auth'
import { propertieMatch } from '../../services/properties.service'
import ChatPropiedad from '../properties/ChatPropiedad'
import Report from '../../components/properties/report-section'
import axios from 'axios'
import Spinner from '../Loader/Spinner'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import NeonText from './NeonText'
import { getMeUserInfo } from '../../services/auth.service'
import { sentRequestChat } from '../../services/chats.service'
import CustomModal from '../modals/modal-custom-info'
import PhotoPropiedad from '../modals/modal-photo-propiedad'
import ContactCard from './TarjetaContacto'
import { object } from 'prop-types'

const formatPrice = (price) => {
  return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(price)
}

const formatData = (data, fallback = 'No disponible') => {
  return data !== null && data !== undefined ? data : fallback
}

const TarjetaPropiedad = ({ token, property }) => {
  const [isShortStay, setIsShortStay] = useState(false)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [copied, setCopied] = useState(false)
  const [isLiked, setIsLiked] = useState(property.like)
  const [requestChatMessage, setRequestChatMessage] = useState('')
  const [chatRequestStatus, setChatRequestStatus] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [markdownContent, setMarkdownContent] = useState('')
  const [chatInProgress, setChatInProgress] = useState(false)
  const [text, setText] = useState('')
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showPictureModal, setShowPictureModal] = useState(false);
  const pictureText = '¿Deseas enviar una solicitud de chat al dueño de este inmueble?'


  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const hasSession = Boolean(token) && Object.keys(user).length !== 0;
  const componenteRef = useRef(null);

  const goBack = () => {
    navigate('/')
  }

  const openLightbox = (index) => {
    setPhotoIndex(index)
    setIsLightboxOpen(true)
    document.body.style.overflow = 'hidden' // Deshabilitar scroll de la página
  }

  const closeLightbox = () => {
    setIsLightboxOpen(false)
    document.body.style.overflow = 'unset' // Habilitar scroll de la página
  }

  const handleDownload = async () => {
    setIsLoading(true)
    try {
      const url = `/app/operation/properties/${property.id}/brandless-pdf`
      const response = await axios.get(url, {
        responseType: 'blob',
      })

      const blob = new Blob([response.data], { type: 'application/pdf' })
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl

      link.download = `property_${property.id}.pdf`
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)

    } catch (error) {
      console.log('Error al descargar pdf propiedad', error)
    } finally {
      setIsLoading(false)
    }

  }

  const handleShare = async () => {
    const shareUrl = `/propiedad-detalle/${property.id}`
    const shareData = {
      title: property.title,
      text: '¡Hey, mira esta propiedad que encontré! \n\n',
      url: `${window.location.origin}${shareUrl}`
    }

    if (navigator.share) {
      try {
        await navigator.share(shareData)
      } catch (err) {
        console.error('Error sharing:', err)
      }
    } else {
      navigator.clipboard.writeText(shareData.url).then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
      }, (err) => {
        console.error('Error copying to clipboard:', err)
      })
    }
  }



  const handleLike = async (id) => {
    const token = localStorage.getItem('token-yo-rento')
    try {
      const resp = await propertieMatch(id, token, true)
      if (resp) {
        setIsLiked(!isLiked) // Actualiza el estado del like
      }
    } catch (error) {
      console.error('Error fetching tags:', error)
    }
  }

  const handleEdit = () => {
    navigate(`/admin/propiedad/${property.id}/editar`)
  }

  const handleChatStart = async (id) => {
    console.log(user);
    setChatInProgress(true);
    try {
      console.log(token);
      const response = await sentRequestChat(id, token);

      if (response.data.message === 'created') {
        setChatRequestStatus('Has enviado una solicitud para conversar con el dueño.');
        setText("Tu solicitud de chat ha sido enviada con exito");
        setShowCustomModal(true);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400) {
        if (error.response.data.message === 'You already sent a request to this property') {
          setChatRequestStatus('¡Solicitud enviada! Tu solicitud de chat ha sido enviada al dueño de este inmueble. Te notificaremos cuando sea aprobada.');
          setText("¡Solicitud enviada!/n Tu solicitud de chat ha sido enviada al dueño de este inmueble. Te notificaremos cuando sea aprobada.");
          setShowCustomModal(true);
        } else {
          setChatRequestStatus('Error: ' + error.response.data.message);
        }
      } else {
        setChatRequestStatus('Ocurrió un error inesperado.');
        console.error('Error sending chat request:', error);
      }
    } finally {
      setChatInProgress(false);
    }
  };

  const onVerifySelfie = async (id) => {

    const token = localStorage.getItem('token-yo-rento');
    if (hasSession === false) {
      componenteRef.current.focusInput();
      return;
    }

    try {
      const response = await getMeUserInfo(token);
      console.log(response.data.user);
      if (!response.data.user.profile_picture) {
        setShowPictureModal(true);
      } else {
        handleChatStart(id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCerrarCustom = () => {
    setShowCustomModal(false);
  }
  const handleCerrar = () => {
    setShowPictureModal(false);
  }

  const images = property.images.map(img => img.image)

  const amenities = [
    { icon: faBedFront, text: `${formatData(property.rooms)} Recámaras`, is_allowed: true },
    { icon: faShower, text: `${formatData(property.bathrooms)} Baños`, is_allowed: true },
    {
      icon: faPaw,
      text: property.is_pet_friendly ? 'Mascotas permitidas' : 'No se permiten mascotas',
      is_allowed: property.is_pet_friendly
    },
    { icon: faGarageCar, text: `${formatData(property.parking_lot)} Estacionamientos`, is_allowed: true },
    {
      icon: faBaby,
      text: property.child_allowed ? 'Niños permitidos' : 'No se permiten niños',
      is_allowed: property.child_allowed
    },
    { icon: faHouse, text: `${formatData(property.surface)} m²`, is_allowed: true },
  ]

  const AmenitiesDisplay = ({ amenities, bestAmenities }) => {
    const renderAmenity = (item, isBest = false) => (
      <motion.div
        key={item.id}
        className={`inline-flex items-center space-x-2 p-4 rounded-full shadow-md transition-transform transform hover:scale-105 
          ${item.is_allowed === false ? 'bg-red-100' : isBest ? 'bg-yellow-500' : 'bg-blue-50'}`}
        whileHover={{ scale: 1.05 }}
      >
        <div className="relative w-6 h-6">
          <FontAwesomeIcon
            icon={ICON_MAP[item.icon] || item.icon}
            className={`text-xl ${isBest ? 'text-white' : 'text-purple-800'}`}
          />
          {item.is_allowed === false && (
            <FontAwesomeIcon
              icon={faBan}
              className="absolute w-full h-full left-0 top-0 text-red-600"
            />
          )}
        </div>
        <p
          className={`font-semibold text-lg ${item.is_allowed === false ? 'text-red-800' : isBest ? 'text-white' : 'text-gray-800'}`}>{item.name}</p>
      </motion.div>
    )

    return (
      <div className="space-y-6">
        <div className="flex flex-wrap gap-6">
          {amenities.map(item => renderAmenity(item))}
        </div>
        {bestAmenities.length > 0 && <h2 className="text-2xl font-bold text-purple-600 mb-4">Mejores Amenidades</h2>}
        <div className="flex flex-wrap gap-6">
          {bestAmenities.map(item => renderAmenity(item))}
        </div>
      </div>
    )
  }

  console.log(property)

  useEffect(() => {
    console.log(property.description)
    setMarkdownContent(property.description)


    if (property.monthly_rent == 0 || isNaN(property.monthly_rent)) {
      setIsShortStay(true)
    }

  }, [property])

  return (
    <>
      <motion.div className="relative" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}>
        <Carousel
          showArrows
          showThumbs={false}
          infiniteLoop
          autoPlay
          interval={5000}
          showStatus={false}
          className="carousel shadow-lg rounded-lg overflow-hidden"
        >
          {property.images.map((img, index) => (
            <motion.div key={index} whileHover={{ scale: 1.05 }} onClick={() => openLightbox(index)}>
              <img src={img.image} alt={`Property ${index + 1}`}
                className="object-cover w-full h-96 md:h-[500px] cursor-pointer" />
            </motion.div>
          ))}
        </Carousel>

        <div className="absolute top-0 left-0 flex items-center justify-between w-full p-4">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-white text-2xl cursor-pointer bg-black bg-opacity-50 p-2 rounded-full"
            onClick={goBack}
          />
          <div className="flex space-x-4">
            {/* Botón flotante para editar si el usuario está logueado y es el dueño */}
            {user && (Number(user.id) === Number(property.owner_id) || (user.user && Number(user.user.userId) === Number(property.owner_id))) && (
              <motion.button
                onClick={handleEdit}
                className="fixed left-14 top-5 z-50 h-14 w-14 bg-purple-600 text-white rounded-full shadow-lg hover:bg-purple-700 transition-transform transform hover:scale-105"
                whileHover={{ scale: 1.05 }}
              >
                <FontAwesomeIcon icon={faEdit} className="text-xl" />
              </motion.button>
            )}
            {
              /*
              * <button onClick={handleDownload}  >
              {!isLoading ? (<FontAwesomeIcon icon={faFileArrowDown} className='text-white text-2xl bg-black bg-opacity-50 p-3 rounded-full' />) : (<Spinner />)}
            </button>*/
            }

            <button onClick={handleShare} className="">
              <FontAwesomeIcon
                icon={faShareNodes}
                className="text-white text-2xl bg-black bg-opacity-50 p-3 rounded-full"
              />
            </button>
            <button onClick={() => handleLike(property.id)} className="">
              <FontAwesomeIcon icon={faHeart}
                className={`text-${isLiked ? 'red-600' : 'white'} text-2xl bg-${isLiked ? 'red-500' : 'black'} bg-opacity-50 p-3 rounded-full`} />
            </button>
          </div>
        </div>

        {!!property.validated_at && (
          <div className="absolute bottom-16 right-3 flex flex-wrap items-right justify-end w-full p-0 z-[5]">
            <motion.div
              whileHover={{ scale: 1.1 }}
              className="inline-flex w-fit items-center whitespace-nowrap rounded-lg border ml-4 px-3 py-1.5 text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent hover:bg-primary/80 bg-azul text-white shadow-lg relative group"
            >
              <FontAwesomeIcon icon={faSealExclamation} className="text-2xl" />
              <p className="mx-2">Confía en <br /> lo que ves</p>
              <FontAwesomeIcon icon={faFileCertificate} className="text-2xl" />

              <div className="absolute bottom-full left mb-2 hidden text-white bg-gray-800 p-2 rounded-lg shadow-lg group-hover:block z-10 whitespace-normal">
                Esta insignia indica que el personal de Yo rento ha validado la información publicada sobre la propiedad.
              </div>
            </motion.div>
          </div>
        )}


        <div
          className="absolute bottom-0 left-0 flex flex-wrap items-center justify-between w-full p-0 badges-container">
          <motion.div whileHover={{ scale: 1.1 }}
            className="inline-flex w-fit items-center whitespace-nowrap rounded-full border ml-4 px-3 py-1.5 text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent hover:bg-primary/80 bg-purple-800 text-white shadow-lg">
            {formatData(property.property_type)}
          </motion.div>
          <div className="flex space-x-2 badges-container-2">
            {property.is_highlighted && (
              <motion.div whileHover={{ scale: 1.1 }}
                className="w-fit rounded-full border px-3 py-1.5 text-sm md:text-md font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent hover:bg-primary/80 bg-purple-800 text-white flex items-center shadow-lg">
                <FontAwesomeIcon icon={faCrown} className="text-yellow-500 text-2xl mr-2" />
                Destacada
              </motion.div>
            )}
            <div className="flex items-center space-x-2 bg-white p-2 rounded-full shadow-lg">
              <FontAwesomeIcon icon={faImages} className="text-purple-800" />
              <span>{formatData(property.images.length)}</span>
            </div>
          </div>
        </div>

        {copied && (
          <div
            className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded-md">
            Enlace copiado al portapapeles
          </div>
        )}
      </motion.div>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageCaption={`Foto ${photoIndex + 1} de ${images.length}`}
          reactModalStyle={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
          }}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4 md:p-6">
        <motion.div className="space-y-4" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.75 }}>
          <div className="flex items-center flex-wrap justify-between">
            <h1 className="text-4xl text-md md:text-3xl font-bold text-purple-600">
              {!isShortStay && (property.monthly_rent != 0 && !isNaN(property.monthly_rent)) && (
                `${formatData(formatPrice(property.monthly_rent), 'Modalidad no disponible')} / Mes`
              )}
              {isShortStay && (property.daily_price != 0 && !isNaN(property.daily_price)) && (
                `${formatData(formatPrice(property.daily_price), 'Modalidad no disponible')} / Día`
              )}
            </h1>

            <div className="flex relative bg-gray-100 rounded-lg overflow-hidden shadow-inner ml-4 mt-4 md:mt-0">
              {property.monthly_rent != 0 && !isNaN(property.monthly_rent) && (
                <div
                  onClick={() => setIsShortStay(false)}
                  className={`cursor-pointer px-4 py-2 font-semibold flex items-center ${!isShortStay ? 'bg-white shadow text-purple-600' : 'text-gray-800'}`}
                >
                  <FontAwesomeIcon icon={faMoon} className="mr-2" />
                  Larga estancia
                </div>
              )}

              {property.daily_price != 0 && !isNaN(property.daily_price) && (
                <div
                  onClick={() => setIsShortStay(true)}
                  className={`cursor-pointer px-4 py-2 font-semibold flex items-center ${isShortStay ? 'bg-white shadow text-purple-600' : 'text-gray-800'}`}
                >
                  <FontAwesomeIcon icon={faSun} className="mr-2" />
                  Corta estancia
                </div>
              )}

              <motion.div
                className={`absolute top-0 bottom-0 left-0 w-1/2 bg-purple-800 transition-transform transform ${isShortStay ? 'translate-x-full' : ''}`}
                style={{ zIndex: -1 }}
                layout
              />
            </div>
          </div>

          <p className="text-gray-600 italic flex items-center">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-purple-800" />
            {property.maintenance_included ? 'Mantenimiento incluido' : `Mantenimiento no incluido, costo: ${formatPrice(property.maintenance)}`}
          </p>

          <h2 className="text-3xl font-bold text-gray-800 space-t-4">{formatData(property.title)}</h2>
          {markdownContent.split('\n').map((paragraph, index) => (
            <React.Fragment key={index}>
              {paragraph}
              <br />
            </React.Fragment>
          ))}


          <div className="space-y-6">
            <MapaDetallePropiedad property={property} />
          </div>
          {property.extra_info && (
            <div>
              <h2 className="text-2xl font-bold text-purple-600 mb-4">Información Adicional</h2>
              {property.extra_info}
            </div>)}

        </motion.div>

        <motion.div className="space-y-6" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}>

          {property?.is_rented && property.is_rented ? (
            <h2 className="text-4xl text-md md:text-3xl font-bold text-purple-600 text-center">Esta propiedad fue rentada</h2>
          )
            : (<div ref={componenteRef}><NeonText chatRequestButton={() => onVerifySelfie(property.id)} /></div>)}

          <h2 className="text-2xl font-bold text-purple-600 mb-4">Amenidades</h2>
          <div className="flex flex-wrap gap-4">
            {amenities.map((item, index) => (
              <motion.div
                key={index}
                className={`inline-flex items-center space-x-2 p-4 rounded-full shadow-md transition-transform transform hover:scale-105 ${item.is_allowed === false ? 'bg-red-100' : 'bg-blue-50'}`}
                whileHover={{ scale: 1.05 }}
              >
                <div className="relative w-6 h-6">
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="text-sm md:text-lg text-purple-600"
                  />
                  {item.is_allowed === false && (
                    <FontAwesomeIcon
                      icon={faBan}
                      className="absolute w-full h-full left-[-4px] top-0 text-red-500"
                    />
                  )}
                </div>
                <p
                  className={`font-semibold ${item.is_allowed === false ? 'text-red-700' : 'text-gray-700'}`}>{item.text}</p>
              </motion.div>
            ))}
          </div>

          <AmenitiesDisplay amenities={property.amenities} bestAmenities={property.bestAmenities} />
          {property.owner && !property.is_rented && hasSession && (
            <ChatPropiedad property={property} token={token} />
          )}

          {hasSession === false && (
            <ContactCard propertyId={property.id} ref={componenteRef} />
          )}

        </motion.div>
        <CustomModal show={showCustomModal} onClose={handleCerrarCustom} text={text} title={''} color={"blue"} />
        <PhotoPropiedad isOpen={showPictureModal} onClose={handleCerrar} onSuccess={() => { handleChatStart(property.id); }} text={pictureText} />
      </div>
      <div>
        <Report property={property} />
      </div>

    </>
  )
}

const props = (state) => ({
  token: state.auth.token,
})

export default connect(props)(TarjetaPropiedad)


