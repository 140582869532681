import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RealState = ({ agentType, step, setStep, contador, isEditing }) => {
    const token = localStorage.getItem('token-yo-rento');
    const [searchTerm, setSearchTerm] = useState('');
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [file, setFile] = useState(null);
    const [newAgencyName, setNewAgencyName] = useState('');
    const [selectedAgencyId, setSelectedAgencyId] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const handleContinue = async () => {

        if (selectedAgencyId) {
            try {
                const response = await axios.post(`/app/operation/property-agencies/${selectedAgencyId}/users`, {
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Inmobiliaria seleccionada:", response.data);
                setStep(step + 1);
            } catch (error) {
                console.error('Error during continuation:', error);
                alert("Ocurrió un error al continuar.");
            }
        } else {
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 5000)
        }
    };

    const handleBack = () => {
        setStep(2);
    };

    const handleAddAgency = async () => {
        if (!newAgencyName || !file) {
            alert("Por favor, completa todos los campos.");
            return;
        }

        const formData = new FormData();
        formData.append('name', newAgencyName);
        formData.append('image', file);

        try {
            const response = await axios.post('/app/operation/property-agencies', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("Inmobiliaria agregada:", response.data);
            setSearchTerm('');
            setOptions([]);
            setShowDropdown(false);
            setNewAgencyName('');
            setFile(null);
            setStep(step + 1);
        } catch (error) {
            console.error('Error adding agency:', error);
            alert("Ocurrió un error al agregar la inmobiliaria.");
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (searchTerm.length > 3) {
                setIsLoading(true);
                try {
                    const url = `/app/operation/property-agencies?search=${searchTerm}`;
                    const response = await axios.get(url, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    const data = response.data.propertyAgencies;
                    setOptions(data);
                    setShowDropdown(data.length > 0);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                setIsLoading(false);
            } else {
                setOptions([]);
                setShowDropdown(false);
            }
        };

        fetchData();
    }, [searchTerm]);

    return (
        <div className="real-state-container w-full">
            <label htmlFor="realstate-search" className="block text-gray-700 mb-8">
                Busca Inmobiliaria:
            </label>
            <input
                type="text"
                id="realstate-search"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (e.target.value.length <= 3) {
                        setShowDropdown(false);
                    }
                }}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Escribe para buscar inmobiliarias..."
            />
            {isLoading ? (
                <p className="my-2">Cargando resultados...</p>
            ) : (
                showDropdown && (
                    <div className="relative mt-2">
                        <select
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm"
                            onChange={(e) => setSelectedAgencyId(e.target.value)} // Actualiza el ID seleccionado
                        >
                            <option value="">Selecciona una Inmobiliaria</option>
                            {options.length > 0 ? (
                                options.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))
                            ) : (
                                <option disabled>No se encontraron resultados</option>
                            )}
                        </select>
                    </div>
                )
            )}
            {options.length === 0 && searchTerm.length > 3 && (
                <div className="mt-4">
                    <h3 className="text-lg mb-8 text-red-600 text-xs">No se encontraron resultados</h3>
                    <h3 className="text-lg ">Agrega tu inmobiliaria a Yo rento</h3>

                    <input
                        type="text"
                        value={newAgencyName}
                        onChange={(e) => setNewAgencyName(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Nombre de la nueva inmobiliaria"
                    />
                    <h3 className="text-lg my-5">Agrega el logo de tu inmobiliaria</h3>
                    <input
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        className="mt-2 block w-full text-gray-700"
                    />
                    <button
                        className="mt-4 w-full flex items-center justify-center text-white bg-purple-800 hover:bg-purple-400 transition duration-300 px-6 py-3 rounded-full"
                        onClick={handleAddAgency}
                    >
                        Agregar nueva inmobiliaria
                    </button>
                </div>
            )}
            {showMessage && (
                <div>
                    <p className="text-red-400 text-xl">Para avanzar tienes que seleccionar alguna inmobiliaria o registrar una nueva.</p>
                </div>
            )}
            <div className="flex justify-between mt-4">
                <button
                    className="w-full md:w-auto flex items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                    onClick={handleBack}
                >
                    Regresar
                </button>
                <button
                    className="btn btn-primary"
                    onClick={handleContinue}
                >
                    Continuar
                </button>
            </div>
        </div>
    );
};

export default RealState;
