import { motion } from 'framer-motion';
import React from 'react';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/header/Navbar';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5
        }
    }
};

const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut"
        }
    }
};

const AvisoPrivacidad = () => {


    return (
        <>
            <Navbar />
            <div className="bg-gray-100 py-[2rem] text-gray-900">
                <div className="bg-white rounded-lg container mx-auto text-gray-900">
                    <div className="container mx-auto py-12 p-6 lg:px-[6rem] xl:px-[15rem]">
                        <h1 className="text-3xl font-bold text-center mb-6">Aviso de privacidad</h1>
                        <motion.div
                            className="space-y-6"
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                        >
                            <motion.section variants={itemVariants}>
                                <>AVISO DE PRIVACIDAD
SWMITH, S.A.P.I. DE C.V. (en lo sucesivo mejor conocido como “Yo Rento” o el
“Responsable”), con domicilio en calle Avenida Américas #1930, piso 2, interiores
6 y 7, colonia Country Club, en la ciudad Guadalajara, Jalisco, México, C.P. 44610,
y portal de internet www.yorento.com.mx, es el responsable del uso y protección de
sus datos personales, y al respecto le informamos lo siguiente:
DATOS PERSONALES
Para llevar a cabo las finalidades descritas en el presente aviso de privacidad,
utilizaremos los siguientes datos personales:
Datos de identificación
• Nombre
• Estado Civil
• Registro Federal de Contribuyentes(RFC)
• Número de Afiliación al Instituto Mexicano del Seguro Social
• Clave única de Registro de Población (CURP)
• Lugar de nacimiento
• Fecha de nacimiento
• Nacionalidad y, en su caso, información migratoria
• Edad
• Firma autógrafa
• Firma electrónica
• Número de Identificación Personal (NIP)
• Imagen y/o fotografía del rostro.
Datos de contacto
• Domicilio
• Teléfono particular
• Teléfono celular
• Correo electrónico
Datos laborales o profesionales
• Puesto o cargo que desempeña
• Referencias laborales
Información patrimonial
• Bienes inmuebles
• Información fiscal
• Historial crediticio
• Ingresos
• Egresos
• Cuentas bancarias
• Número de tarjetas de crédito
• Información sobre comportamiento crediticio
Los datos personales descritos con anterioridad, se recolectan mediante: i) el
suministro voluntario y directo de tu información a través de nuestros formatos
impreso, digitalmente a través de formularios en nuestro sitio web o vía correo
electrónico; ii) el suministro voluntario de tu información y tus datos personales en
entrevistas presenciales o telefónicas con nuestro personal autorizado, iii) la
utilización de fuentes de acceso público, como las redes sociales, y de otras fuentes
públicas disponibles en el mercado; y iv) sociedades de información crediticia.
De igual manera, los datos personales proporcionados pueden constar en
información que puede ser solicitada a los titulares, dependiendo el proceso o
trámite que se realice ante Yo Rento, los cuales de manera enunciativa más no
limitativa los documentos serán:
i) Comprobantes de domicilio; ii) Identificaciones oficiales (credencial de elector,
pasaporte, cédula profesional); iii) Estados de Cuenta; iv) declaraciones fiscales; v)
comprobantes de ingresos (recibos de nómina, estados de cuenta, recibos de
honorarios, comprobantes fiscales); vi) comprobantes de estudios y grados
académicos, así como cédulas profesionales; v) Comprobantes de pago de
obligaciones mercantiles, fiscales, seguridad social, etc. Contratos, convenios y
documentos relacionados; y vi) Escrituras, pólizas y actas otorgadas ante fedatarios
públicos.
El Titular manifiesta que los datos personales proporcionados al Responsable son
veraces y actualizados, así mismo se obliga a comunicar al Responsable cualquier
modificación a los datos personales proporcionados con la mayor brevedad posible.
Los datos personales que proporcione se conservarán o destruirán según se
cumplan las finalidades de Yo Rento y sujetos a los términos señalados en el
presente Aviso, así como para el cumplimiento de la normatividad aplicable, ya sea
en materia de protección de datos personales, mercantil, administrativa y/o fiscal
vigente.
DATOS PERSONALES SENSIBLES
Yo Rento reconoce que ciertos datos sometidos a tratamiento revisten o pueden
revestir la calidad de Datos Personales Sensibles, por lo que Yo Rento almacenará
los datos personales y los datos personales Sensibles mediante sistemas manuales 
o electrónicos con acceso limitado, con el objetivo de protegerlos contra pérdidas,
uso fraudulento, accesos no autorizados, revelación, modificación o destrucción.
Asimismo, le informamos que en Yo Rento no recabamos ni tratamos Datos
Personales Sensibles como origen racial o étnico, estado de salud, información
genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones
políticas o preferencia sexual.
FINALIDADES Y/O USOS DE LOS DATOS PERSONALES
Yo Rento recopila sus datos personales con el propósito (finalidades primarias) de:
• Registrar su usuario en la plataforma Yo Rento;
• Brindar atención al usuario;
• Proveer los servicios y productos de Yo Rento requeridos por Usted, para
hacer cumplir y/o ejecutar sus necesidades;
• Gestionar citas con los representantes de venta;
• Integrar expedientes, mantener bases de datos actualizadas y dar
tratamiento a los mismos, ya sea directamente o a través de un tercero;
• Realizar procedimientos de investigación;
• Realizar búsquedas de información en fuentes de acceso públicas sobre
antecedentes legales;
• Realizar búsquedas de información ante sociedades de información
crediticia;
• Realizar búsquedas de información y antecedentes en motores de
búsqueda, redes sociales y otras fuentes de información disponibles en
internet;
• Verificar las referencias profesionales y personales con terceros;
• Generar perfiles de riesgo y recomendaciones;
• Procesar pagos de los servicios que brinda la plataforma a través de los
distintos medios de pago señalados en el sitio web; y
• Mantener resguardos físicos, electrónicos y de procedimiento de la
información personal en términos de la legislación y regulación aplicable, y
cumplir con los requerimientos legales en materia civil, mercantil,
administrativa y fiscal.
De manera adicional, utilizaremos su información personal para las siguientes
finalidades secundarias que no son necesarias para el servicio solicitado, pero que
nos permiten y facilitan brindarle una mejor atención:
• Mercadotecnia o publicitaria;
• Prospección comercial;
• Gestionar el envío de comunicados con avisos, mensajes, novedades,
invitación a eventos y reuniones; y
• Evaluar la calidad de nuestros productos y servicios.
CONSENTIMIENTO
Usted en este acto reconoce que al proporcionar sus datos personales de manera
presencial o a través de cualquier medio remoto, incluyendo nuestro sitio web, el
presente aviso de privacidad le ha sido dado a conocer, mismo que ha leído,
entendido y aceptado, por lo cual otorga su consentimiento para que recabemos,
tratemos y, en su caso, transfiramos sus datos personales, en los términos del
presente Aviso.
TRANSFERENCIA DE DATOS PERSONALES
Le informamos que sus datos personales pueden ser compartidos dentro y fuera del
país con las siguientes personas, empresas, organizaciones o autoridades distintas
a nosotros, sin requerir su consentimiento, para los siguientes fines:
a) Con Arrendadores, arrendatarios y asesores inmobiliarios, a efecto de que
valoren y en su caso celebren el contrato de arrendamiento del inmueble materia de
la operación de que se trate; b) Con empresas filiales o afiliadas integrantes del
grupo corporativo de “Yo Rento”, a efecto de brindar apoyo en sus respectivas
operaciones y prestar los servicios contratados; c) Con autoridades, a efecto de dar
cumplimiento a alguna ley, reglamento o disposición legal aplicable cuando la
transferencia sea obligatoria; y d) Con sociedades de información crediticia, a efecto
de realizar las investigaciones correspondientes.
Le informamos que sus datos personales biométricos en ningún momento serán
transferidos a terceros.
EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN,
CANCELACIÓN U OPOSICIÓN (ARCO)
Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué
los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su
derecho solicitar la corrección de su información personal en caso de que esté
desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de
nuestros registros o bases de datos cuando considere que la misma no está siendo
utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos 
personales para fines específicos (Oposición). Estos derechos se conocen como
derechos ARCO.
Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la
solicitud respectiva a través del siguiente medio: (i) Presencial, formulando la
solicitud en el domicilio de Yo Rento o mediante correo electrónico dirigido a la
dirección contacto@yorento.com.mx.
La solicitud de ejercicio de derechos ARCO podrá ser formulada por el Titular de los
datos personales o, en su caso, por su representante legal, para lo cual deberá
exhibirse una copia de los documentos que acrediten su identidad (credencial para
votar, pasaporte o cualquier otra identificación oficial) o, en su caso, los documentos
que acrediten la identidad del Titular y de su representante, así como aquellos que
acrediten su representación legal, cuyo original deberá presentar.
Además, la solicitud deberá contener: a) Una descripción clara y precisa de los
datos personales respecto de los cuales busca ejercer alguno de los Derechos
ARCO; b) Cualquier documento o información que facilite la localización de sus
datos personales; c) En caso de solicitar una rectificación de sus datos personales,
deberá de indicar también, las modificaciones a realizarse y aportar la
documentación que sustente su petición; y d) Información de contacto.
Yo Rento emitirá una respuesta en un plazo máximo de 20 (veinte) días hábiles
para informar sobre la procedencia de la solicitud de sus derechos ARCO, lo que se
hará de su conocimiento a través del correo electrónico que haya proporcionado.
Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el
tratamiento de sus datos personales. Sin embargo, es importante que tenga en
cuenta que no en todos los casos podremos atender su solicitud o concluir el uso
de forma inmediata, ya que es posible que por alguna obligación legal requiramos
seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para
ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir
prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
INSTANCIA RESPONSABLE
Para cualquier consulta o petición relacionada con el tratamiento de tus datos
personales, incluyendo su actualización, limitación de su uso o divulgación, favor de
contactar a nuestro equipo responsable en nuestro domicilio o enviando un correo
electrónico a la dirección contacto@yorento.com.mx.
TECNOLOGÍAS DE RASTREO 
Le informamos que en nuestra página de internet utilizamos cookies, web beacons
u otras tecnologías, a través de las cuales es posible monitorear su comportamiento
como usuario de internet, así como brindarle un mejor servicio y experiencia al
navegar en nuestra página. Los datos personales que recabamos a través de estas
tecnologías, los utilizaremos para los siguientes fines:
• Identificar la forma en la cual usted hace uso de nuestros servicios con la
finalidad de mejorar su rendimiento y futuros desarrollos.
• Recordar sus preferencias, opciones de navegación y funciones
personalizadas en nuestras aplicaciones.
• Permitirle un uso adecuado y operación de nuestras aplicaciones.
• Proporcionarle una experiencia personalizada en nuestras aplicaciones.
Los datos personales que obtenemos de estas tecnologías de rastreo son los
siguientes:
• Identificadores, nombre de usuario y contraseñas de una sesión
• Idioma preferido por el usuario
• Región en la que se encuentra el usuario
• Tipo de navegador del usuario
• Tipo de sistema operativo del usuario
• Fecha y hora del inicio y final de una sesión de un usuario
• Páginas web visitadas por un usuario
• Búsquedas realizadas por un usuario
• Publicidad revisada por un usuario
• Listas y hábitos de consumo en páginas de compras
Se le recuerda que como Titular puede deshabilitar o ajustar el uso de cookies
siguiendo los procedimientos del navegador de internet que utiliza a través de los
siguientes enlaces:
Microsoft Edge:
View cookies in Microsoft Edge - Microsoft Support
Mozilla Firefox:
http://support.mozilla.com/en-US/kb/Cookies
Google Chrome:
https://support.google.com/chrome/answer/95647?hl=es&ref_topic=7438325&sjid=
8032322434921989538-NC
Safari: 
http://support.apple.com/kb/PH5042
Opera:
http://www.opera.com/browser/tutorials/security/privacy/
CAMBIOS EN EL AVISO DE PRIVACIDAD
El presente aviso de privacidad puede sufrir modificaciones, cambios o
actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias
necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de
privacidad; de cambios en nuestro modelo de negocio, o por otras causas,
reservándose Yo Rento el derecho, bajo su exclusiva discreción, de cambiar,
modificar, agregar o eliminar partes del mismo en cualquier momento.
En tal caso, nos comprometemos a mantenerlo informado sobre los cambios que
pueda sufrir el presente aviso de privacidad, a través de nuestro sitio web.</>

                            </motion.section>
                            {/* Puedes añadir más secciones aquí */}
                        </motion.div>
                    </div>
                </div>
            </div>

            <Footer />

        </>

    );
};

export default AvisoPrivacidad;
