import { faPaperPlane, faStar } from '@fortawesome/pro-solid-svg-icons';
import { faMessages } from '@fortawesome/pro-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import chatBanner from '../../assets/drawe-chat-unauth.png';
import womanApproved from '../../assets/drawe-icon-conversation.png';
import { AuthContext } from '../../provider/auth';
import { sentRequestChat } from '../../services/chats.service';
import { useNavigate } from 'react-router-dom';
import logoUser from '../../assets/user-logo.svg';
import PhotoPropiedad from '../modals/modal-photo-propiedad';
import { createPortal } from 'react-dom';
import { getMeUserInfo } from '../../services/auth.service';
import CustomModal from '../modals/modal-custom-info';
import NeonText from '../cards/NeonText';

const ChatPropiedad = ({ property, token }) => {
    const [requestChatMessage, setRequestChatMessage] = useState('');
    const [chatRequestStatus, setChatRequestStatus] = useState(null);
    const [chatInProgress, setChatInProgress] = useState(false);
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [text, setText] = useState('');
    const [showCustomModal, setShowCustomModal] = useState(false);
    const navigate = useNavigate();
    const pictureText = '¿Deseas enviar una solicitud de chat al dueño de este inmueble?';

    const goToChat = (chatId, subTabView) => {
        navigate(`/admin/chats?subTabView=${subTabView}&chatId=${chatId}`);
    };

    const onVerifySelfie = async (id) => {

        const token = localStorage.getItem('token-yo-rento');

        try {
            const response = await getMeUserInfo(token);
            console.log(response.data.user);
            if (!response.data.user.profile_picture) {
                setShowPictureModal(true);
            } else {
                handleChatStart(id);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleCerrar = () => {
        setShowPictureModal(false);
    }
    const handleCerrarCustom = () => {
        setShowCustomModal(false);
    }

    const handleChatStart = async (id) => {
        setChatInProgress(true); // Indicar que se está procesando el envío del chat
        try {
            const response = await sentRequestChat(id, token);
            // Manejar las respuestas esperadas de la API
            if (response.data.message === 'created') {
                setChatRequestStatus('Has enviado una solicitud para conversar con el dueño.');
                setText("Tu solicitud de chat ha sido enviada con exito");
                setShowCustomModal(true);
            }
        } catch (error) {
            // Verifica si el error es el código de estado 400 con el mensaje esperado
            console.error(error);
            if (error.response && error.response.status === 400) {
                if (error.response.data.message === 'You already sent a request to this property') {
                    setChatRequestStatus('¡Solicitud enviada! Tu solicitud de chat ha sido enviada al dueño de este inmueble. Te notificaremos cuando sea aprobada.');
                    setText("¡Solicitud enviada!/n Tu solicitud de chat ha sido enviada al dueño de este inmueble. Te notificaremos cuando sea aprobada.");
                    setShowCustomModal(true);
                } else {
                    setChatRequestStatus('Error: ' + error.response.data.message);
                }
            } else {
                setChatRequestStatus('Ocurrió un error inesperado.');
                console.error('Error sending chat request:', error);
            }
        } finally {
            setChatInProgress(false); // Finalizar el procesamiento
        }
    };

    return (
        <motion.div className='space-y-4 bg-white rounded-lg shadow-lg p-6' initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} transition={{ delay: 0.5 }}>
            <div className='flex items-center md:justify-between justify-around flex-wrap border-b pb-4 mb-4'>
                <div className='relative flex items-center space-x-4'>
                    <span className='relative flex h-12 w-12 shrink-0 overflow-hidden rounded-full border-2 border-purple-800'>
                        {property.owner.agency_image ? (
                            <>
                                <img className='aspect-square h-full w-full' alt='Agency Avatar' src={property.owner.agency_image} />

                                {property.owner.profile_picture ? (<span className='absolute bottom-0 right-0 h-5 w-5 rounded-full border-2 border-white overflow-hidden'>
                                    <img className='aspect-square h-full w-full' alt='Owner Avatar' src={property.owner.profile_picture} />
                                </span>) : (<span className='absolute bottom-0 right-0 h-5 w-5 rounded-full border-2 border-white overflow-hidden'>
                                    <img className='aspect-square h-full w-full' alt='Default Avatar' src={logoUser} />
                                </span>)}
                            </>
                        ) : property.owner.profile_picture ? (
                            <img className='aspect-square h-full w-full' alt='Owner Avatar' src={property.owner.profile_picture} />
                        ) : (
                            <img className='aspect-square h-full w-full' alt='Default Avatar' src={logoUser} />
                        )}
                    </span>
                    <div>
                        <p className='text-gray-600'>Publicada {property.posted_ago_label}</p>
                        <p className='font-bold text-lg text-gray-800'>Por: {property.owner.name}</p>
                        {property.owner.agency_name && <p className='text-gray-800'>{property.owner.agency_name}</p>}
                    </div>
                </div>
                <div className='flex flex-col items-center'>
                    {property.owner.tenant_score.stars > 0 ? (
                        <div className='flex items-center space-x-2'>
                            {[...Array(5)].map((_, index) => (
                                <FontAwesomeIcon key={index} icon={faStar} className='text-yellow-500' />
                            ))}
                            <p className='font-bold text-lg text-gray-800'>{property.owner.tenant_score.stars}.0</p>
                        </div>
                    ) : (
                        <div className="flex items-center space-x-2 bg-gray-100 p-3 rounded-lg shadow-sm">
                            <FontAwesomeIcon icon={faStar} className="text-gray-400 w-4 h-4" />
                            <div className="text-sm text-gray-600">
                                <p>Este arrendador aún no tiene calificaciones.</p>
                                <p className="text-gray-500">¡Sé el primero en dejar una!</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='h-64 bg-gray-100 rounded-lg p-4 overflow-auto contenedor_chat'>
                <AnimatePresence>
                    {chatRequestStatus ? (
                        <motion.div
                            className='flex flex-col items-center justify-center h-full'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <img src={womanApproved} alt='Aprobado' className='w-32 h-32' />
                            <p className='text-gray-600 text-center mt-4'>{chatRequestStatus}</p>
                        </motion.div>
                    ) : property.chats && property.chats.length > 0 ? (
                        <motion.div
                            className='flex flex-col space-y-4'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            {property.chats.map((chat, index) => (
                                <motion.div
                                    key={index}
                                    className={`flex items-${chat.type === 'user' ? 'start' : 'end'} space-x-3 max-w-xs ${chat.type === 'user' ? '' : 'self-end'}`}
                                    initial={{ opacity: 0, x: chat.type === 'user' ? -20 : 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                >
                                    <img
                                        className='h-8 w-8 rounded-full'
                                        src={chat.avatar}
                                        alt={chat.name}
                                    />
                                    <div>
                                        <div className={`bg-${chat.type === 'user' ? 'white' : 'purple-800'} p-3 rounded-lg shadow-md ${chat.type === 'user' ? '' : 'text-white'}`}>
                                            <p>{chat.message}</p>
                                        </div>
                                        <p className='text-gray-500 text-sm mt-1'>
                                            {chat.date}
                                        </p>
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                    ) : (
                        <motion.div
                            className='flex flex-col items-center justify-center h-full'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <img src={chatBanner} alt='Inicia un chat con el arrendador' className='w-32 h-32' />
                            <p className="text-gray-600 mt-4">
                                {property.chat_status === 'requested'
                                    ? 'Tu solicitud de mensaje fue enviada y tiene que ser aprobada por el arrendador.'
                                    : property.chat_status === 'accepted'
                                        ? 'Tienes un chat en progreso, ¡Buena suerte!'
                                        : '¿Te interesa esta casa? ¡Inicia una conversación con el dueño!'}
                            </p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            {!chatRequestStatus && property.chat_status !== 'requested' && property.chat_status !== 'accepted' && (
                <motion.div className='mt-4 flex items-center flex-wrap justify-around' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6 }}>
                    <input
                        type='text'
                        className='flex-grow rounded-full border border-gray-300 p-3 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:border-transparent'
                        placeholder='Escribe tu mensaje...'
                        value={requestChatMessage}
                        onChange={(e) => setRequestChatMessage(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && onVerifySelfie(property.id)}
                        disabled={chatInProgress}
                    />
                    <motion.button
                        className={`bg-purple-800 w-full md:w-auto mt-2 md:mt-0 text-white rounded-full p-3 hover:bg-purple-600 transition ease-in-out duration-300 transform hover:scale-105 md:ml-2 ${chatInProgress ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={() => onVerifySelfie(property.id)}
                        whileHover={{ scale: 1.1 }}
                        disabled={chatInProgress}
                    >
                        {chatInProgress ? 'Enviando...' : 'Comenzar Chat'}
                        <FontAwesomeIcon icon={faPaperPlane} className='w-6 h-4 ml-2' />
                    </motion.button>
                </motion.div>
            )}
            {property.chat_status === 'accepted' && (
                <motion.div className='text-center'>
                    <motion.button
                        className={`bg-purple-800 w-full md:w-auto mt-2 md:mt-0 text-white rounded-full p-3 hover:bg-purple-600 transition ease-in-out duration-300 transform hover:scale-105 md:ml-2 ${chatInProgress ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={() => goToChat(property.chat_room_id, 3)}
                        whileHover={{ scale: 1.1 }}
                        disabled={chatInProgress}
                    >
                        Ir a chat
                        <FontAwesomeIcon icon={faPaperPlane} className='w-6 h-4 ml-2' />
                    </motion.button>
                </motion.div>

            )}
            {showPictureModal && createPortal(<PhotoPropiedad isOpen={showPictureModal} onClose={handleCerrar} onSuccess={() => { handleChatStart(property.id); }} text={pictureText} />,
                document.body
            )}

            {showCustomModal && createPortal(<CustomModal show={showCustomModal} onClose={handleCerrarCustom} text={text} title={''} color={"blue"} />,
                document.body
            )}
            {property.chat_status === "accepted" ? createPortal(
                <button
                    onClick={() => goToChat(property.chat_room_id, 3)}
                    className={`fixed bottom-20 right-5 z-[9999999999] bg-blue-500 w-20 h-20 flex justify-center items-center rounded-full shadow-lg text-white hover:bg-blue-600 transition-colors text-xl`}
                    aria-label="Enviar mensaje de WhatsApp"
                >
                    <FontAwesomeIcon icon={faMessages} size="2x"
                        aria-label="Enviar mensaje de WhatsApp" />
                </button>
                , document.body)
                :
                createPortal(
                    <button
                        onClick={() => onVerifySelfie(property.id)}
                        className={`fixed bottom-20 right-5 z-[9999999999] bg-blue-500 w-20 h-20 flex justify-center items-center rounded-full shadow-lg text-white hover:bg-blue-600 transition-colors text-xl`}
                        aria-label="Enviar mensaje de WhatsApp"
                    >
                        <FontAwesomeIcon icon={faMessages} size="2x"
                            aria-label="Enviar mensaje de WhatsApp" />
                    </button>
                    , document.body)}

        </motion.div>
    );
};

export default ChatPropiedad;
