import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRight, faLeft } from '@fortawesome/pro-solid-svg-icons';

const NeonText = ({ chatRequestButton }) => {
    return (
        <div className="relative flex items-center justify-center">
            <button
                className="relative text-center px-6 py-4 rounded-full bg-gradient-to-br from-cyan-300 via-cyan-400 to-cyan-600 shadow-lg animate-bounce"
                onClick={() => chatRequestButton()}
            >
                <h2 className="font-medium text-xl text-white">
                    ¡Contacta al anunciante!
                </h2>
            </button>
        </div>
    );
};

export default NeonText;
