import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { updatePropertieStatus } from '../../services/properties.service';
import { postRenterScore } from '../../services/score.service';

const RenterEvaluation = ({ renter, rentHistoryId, onClose, property }) => {
    const [rate, setRate] = useState(null);
    const [publicComment, setPublicComment] = useState('');
    const [privateComment, setPrivateComment] = useState('');
    const [complianceStars, setComplianceStars] = useState(0);
    const [punctualityStars, setPunctualityStars] = useState(0);
    const [renewContractStars, setRenewContractStars] = useState(0);
    const [optimalConditionStars, setOptimalConditionStars] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const { token } = useSelector(state => state.auth);

    if (!renter) return <div>Cargando...</div>;

    const validateFields = () => {
        const newErrors = {};
        if (!rate) newErrors.rate = 'Este campo es obligatorio';
        if (!publicComment) newErrors.publicComment = 'Este campo es obligatorio';
        if (!privateComment) newErrors.privateComment = 'Este campo es obligatorio';
        if (!complianceStars) newErrors.complianceStars = 'Este campo es obligatorio';
        if (!punctualityStars) newErrors.punctualityStars = 'Este campo es obligatorio';
        if (!renewContractStars) newErrors.renewContractStars = 'Este campo es obligatorio';
        if (!optimalConditionStars) newErrors.optimalConditionStars = 'Este campo es obligatorio';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleEvaluationSubmit = async () => {

        if (!validateFields()) return;

        setIsLoading(true);
        setMessage(null);

        const evaluationData = {
            rate,
            publicComment,
            complianceStars,
            punctualityStars,
            renewContractStars,
            optimalConditionStars,
            privateComment,
            rentHistoryId
        };

        try {
            await postRenterScore(evaluationData, token);
            setMessage('Evaluación enviada con éxito.');

            const payload = {
                is_rented: false,
            };

            await updatePropertieStatus(property.id, payload, token);

            setIsLoading(false);
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            setMessage('Error al enviar la evaluación. Inténtalo nuevamente.');
            setIsLoading(false);
        }
    };

    const starVariants = {
        initial: { scale: 1 },
        hover: { scale: 1.3, rotate: 15 },
    };

    return (
        <motion.div
            className="px-6 py-4 space-y-6 bg-white rounded-lg"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <motion.h2
                className="text-center text-2xl font-semibold text-purple-600 mb-4"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3 }}
            >
                Evalúa a tu inquilino
            </motion.h2>
            <div className="flex flex-col items-center mb-6">
                <motion.img
                    src={renter.profile_picture}
                    alt={renter.name}
                    className="w-24 h-24 rounded-full mb-2 object-cover"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                />
                <p className="text-lg font-medium text-center">{renter.name}</p>
            </div>
            <div className="mb-6 text-center">
                <p className="text-gray-700 mb-2">¿Qué calificación le darías?</p>
                <div className="flex justify-center space-x-4">
                    {['Excelente', 'Buena', 'Regular', 'Mala'].map((label, index) => (
                        <motion.button
                            key={index}
                            className={`px-6 py-3 rounded-full transition-colors duration-200 ${rate === label ? 'bg-purple-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                            onClick={() => setRate(label)}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            {label}
                        </motion.button>
                    ))}
                </div>
                {errors.rate && <p className="text-red-500 mt-2">{errors.rate}</p>}
            </div>
            <div className="mb-4 text-center">
                <p className="text-gray-700 mb-2">¿Cumplió las normas convenidas en el contrato?</p>
                <div className="flex justify-center">
                    {[1, 2, 3, 4, 5].map((rating) => (
                        <motion.div
                            key={rating}
                            initial="initial"
                            whileHover="hover"
                            variants={starVariants}
                            transition={{ duration: 0.2 }}
                        >
                            <FontAwesomeIcon
                                icon={faStar}
                                className={`cursor-pointer ${complianceStars >= rating ? 'text-purple-600' : 'text-gray-300'}`}
                                style={{ fontSize: '2rem' }}
                                onClick={() => setComplianceStars(rating)}
                            />
                        </motion.div>
                    ))}
                </div>
                {errors.complianceStars && <p className="text-red-500 mt-2">{errors.complianceStars}</p>}
            </div>
            <div className="mb-4 text-center">
                <p className="text-gray-700 mb-2">¿Fue puntual con los pagos?</p>
                <div className="flex justify-center">
                    {[1, 2, 3, 4, 5].map((rating) => (
                        <motion.div
                            key={rating}
                            initial="initial"
                            whileHover="hover"
                            variants={starVariants}
                            transition={{ duration: 0.2 }}
                        >
                            <FontAwesomeIcon
                                icon={faStar}
                                className={`cursor-pointer ${punctualityStars >= rating ? 'text-purple-600' : 'text-gray-300'}`}
                                style={{ fontSize: '2rem' }}
                                onClick={() => setPunctualityStars(rating)}
                            />
                        </motion.div>
                    ))}
                </div>
                {errors.punctualityStars && <p className="text-red-500 mt-2">{errors.punctualityStars}</p>}
            </div>
            <div className="mb-4 text-center">
                <p className="text-gray-700 mb-2">¿Considerarías rentarle este inmueble nuevamente o extender el plazo?</p>
                <div className="flex justify-center">
                    {[1, 2, 3, 4, 5].map((rating) => (
                        <motion.div
                            key={rating}
                            initial="initial"
                            whileHover="hover"
                            variants={starVariants}
                            transition={{ duration: 0.2 }}
                        >
                            <FontAwesomeIcon
                                icon={faStar}
                                className={`cursor-pointer ${renewContractStars >= rating ? 'text-purple-600' : 'text-gray-300'}`}
                                style={{ fontSize: '2rem' }}
                                onClick={() => setRenewContractStars(rating)}
                            />
                        </motion.div>
                    ))}
                </div>
                {errors.renewContractStars && <p className="text-red-500 mt-2">{errors.renewContractStars}</p>}
            </div>
            <div className="mb-4 text-center">
                <p className="text-gray-700 mb-2">¿El inmueble te fue devuelto en óptimas condiciones?</p>
                <div className="flex justify-center">
                    {[1, 2, 3, 4, 5].map((rating) => (
                        <motion.div
                            key={rating}
                            initial="initial"
                            whileHover="hover"
                            variants={starVariants}
                            transition={{ duration: 0.2 }}
                        >
                            <FontAwesomeIcon
                                icon={faStar}
                                className={`cursor-pointer ${optimalConditionStars >= rating ? 'text-purple-600' : 'text-gray-300'}`}
                                style={{ fontSize: '2rem' }}
                                onClick={() => setOptimalConditionStars(rating)}
                            />
                        </motion.div>
                    ))}
                </div>
                {errors.optimalConditionStars && <p className="text-red-500 mt-2">{errors.optimalConditionStars}</p>}
            </div>
            <div className="mb-6 text-center">
                <p className="text-gray-700 mb-2">En base a tu calificación, ¿puedes decirnos cómo fue tu experiencia?</p>
                <textarea
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                    placeholder="Escribe tu comentario público aquí..."
                    value={publicComment}
                    onChange={(e) => setPublicComment(e.target.value)}
                />
                {errors.publicComment && <p className="text-red-500">{errors.publicComment}</p>}
                <p className="text-gray-700 mb-2">¿Tienes algún comentario que quieras hacerle llegar a tu arrendatario(a)?</p>
                <textarea
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Escribe tu comentario privado aquí..."
                    value={privateComment}
                    onChange={(e) => setPrivateComment(e.target.value)}
                />
                {errors.privateComment && <p className="text-red-500">{errors.privateComment}</p>}
            </div>
            <button
                className={`w-full py-3 font-semibold rounded-lg ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-purple-600 text-white'
                    }`}
                onClick={handleEvaluationSubmit}
                disabled={isLoading}
            >
                {isLoading
                    ? 'Enviando...'
                    : Object.keys(errors).length > 0
                        ? 'Por favor llena todos los campos'
                        : message || 'Enviar evaluación'}
            </button>
        </motion.div>
    );
};

export default RenterEvaluation;
