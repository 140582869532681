import { useEffect, useState } from 'react';
import investigation from '../../assets/drawe-investigation.png';
import identityPayment from '../../assets/drawe-woman-payment.png';
import { motion } from 'framer-motion';
import CheckoutFormPassport from './PassportPaymentForm';
import { getMeUserInfo, getTruoraLink, getTruoraStatus } from '../../services/auth.service';
import draweSelfie from '../../assets/drawe-selfie.png';
import Spinner from '../../components/Loader/Spinner';
import { faBadgeCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenGood from '../../assets/drawe-men-good.png';
import ConfirmUpload from '../../assets/drawe-confirm-upload.png'

const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
};

const steps = {
    start: 'inicio',
    goPay: 'goPay',
    payment: 'payment',
    truora: 'truora',
};

const ValidateIdentity = () => {
    const [step, setStep] = useState(steps.start);
    const [hasPaid, setHasPaid] = useState(false);
    const [hasIdentityVerified, setHasIdentityVerified] = useState(false);
    const [url, setUrl] = useState('');
    const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handlePaymentConfirmed = () => {
        setIsPaymentConfirmed(true);
        setHasPaid(true);
    };

    useEffect(() => {
        const getMyInfo = async () => {
            setIsLoading(true);
            try {
                const response = await getMeUserInfo();
                if (response.status === 200) {
                    setHasPaid(response.data.user.identity_verification_paid);
                    setHasIdentityVerified(response.data.user.identity_verified);
                }
            }
            catch (error) {
                console.error("Error al obtener user Info", error);
            }
            finally {
                setIsLoading(false);
            }

        };

        getMyInfo();
    }, []);

    useEffect(() => {
        const getInitialStatus = async () => {
            setIsLoading(true);
            try {
                const response = await getTruoraStatus();
                if (response.status === 200) {
                    const currentStatus = response.data.status;
                    setStatus(currentStatus);
                    if (currentStatus === 'failure') {
                        setStep(steps.start);
                    } else if (currentStatus === 'pending') {
                        setStep(steps.truora);
                    } else if (currentStatus === 'success') {
                        setStep(steps.start);
                    }
                }
            } catch (error) {
                console.error("Error al obtener el status de truora", error);
            }
            finally {
                setIsLoading(false);
            }
        };

        if (hasPaid && !hasIdentityVerified) {
            getInitialStatus();
        }
    }, [hasPaid, hasIdentityVerified]);

    useEffect(() => {
        console.log("se dispara esto")
        console.log("ispayment confirmed", isPaymentConfirmed);
        if (isPaymentConfirmed) {
            //setStep(steps.truora);
            setStep((previousValue) => steps.truora);
            console.log(step);
            console.log(steps.truora);
            console.log(steps);
        }
    }, [isPaymentConfirmed]);

    useEffect(() => {
        const getLinkTruora = async () => {
            try {
                const response = await getTruoraLink();
                if (response.status === 200) {
                    setUrl(response.data.link);
                }
            } catch (error) {
                console.error("Error al obtener el link de truora");
            }
        };

        if (step === steps.truora) {
            setTimeout(() => {
                getLinkTruora();
            }, 1000);
        }
    }, [step]);

    const handleContinuar = () => {
        if (hasPaid) {
            setStep(steps.truora);
        } else {
            setStep(steps.goPay);
        }
    };

    const handlePagar = () => {
        setStep(steps.payment);
    };

    if (hasIdentityVerified) {
        return (
            <motion.div
                className="p-6 text-center flex-col "
                variants={fadeUpVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <div className="flex justify-center">
                    <FontAwesomeIcon icon={faBadgeCheck} className="text-purple-800 text-2xl" />
                    <p className='text-[24px] text-purple-800 text-center font-semibold mb-8 ml-3'>Identidad Verificada con Éxito</p>
                </div>
                <div className="flex justify-center mb-6">
                    <img src={MenGood} />
                </div>


            </motion.div>
        );
    }


    return (

        <div>
            <h1 className="text-[24px] text-purple-800 text-center font-semibold mb-8">Valida tu identidad</h1>
            {isLoading ? (
                <div className="flex items-center justify-center p-4">
                    <Spinner />
                </div>
            ) : (
                <motion.div
                    className="p-6"
                    variants={fadeUpVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >

                    {!hasPaid ? (
                        <>
                            {step === steps.start && (
                                <motion.div
                                    variants={fadeUpVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                >
                                    <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                                        ¡Atención!
                                    </h3>

                                    <div className="flex justify-center mb-6">
                                        <img
                                            src={investigation}
                                            alt="Validación de identidad"
                                            className="rounded-lg"
                                        />
                                    </div>

                                    <p className="text-gray-700 text-center">
                                        Ayúdanos a hacer de YoRento una comunidad más grande y segura. Por favor, valida tu identidad.
                                    </p>
                                    <div className="flex justify-center text-center">
                                        <button
                                            onClick={handleContinuar}
                                            className="mt-2 px-4 py-2 bg-purple-800 text-white rounded-md hover:bg-purple-600 transition"
                                        >
                                            Continuar
                                        </button>
                                    </div>
                                </motion.div>
                            )}

                            {step === steps.goPay && (
                                <motion.div
                                    variants={fadeUpVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                >
                                    <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                                        Efectúa tu pago de validación
                                    </h3>

                                    <div className="flex justify-center mb-6">
                                        <img
                                            src={identityPayment}
                                            alt="Pago de validación"
                                            className="rounded-lg"
                                        />
                                    </div>

                                    <p className="text-gray-700 text-center mb-4">
                                        Realiza un pago único para continuar con la validación de tu identidad.
                                    </p>
                                    <div className="flex justify-center text-center">
                                        <button
                                            onClick={handlePagar}
                                            className="mt-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition"
                                        >
                                            Pagar 99 MXN
                                        </button>
                                    </div>
                                </motion.div>
                            )}

                            {step === steps.payment && (
                                <div>
                                    <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                                        Completa tu validación
                                    </h3>

                                    <div>
                                        <CheckoutFormPassport onPaymentConfirmed={handlePaymentConfirmed} />
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex flex-col items-center text-center">
                            {status === 'failure' && (
                                <>
                                    <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                                        Verificación Fallida
                                    </h3>
                                    <p className="font-bold text-red-600 mb-6">
                                        Verificación de identidad fallida, favor de contactar a soporte técnico.
                                    </p>
                                    <button
                                        onClick={() => window.open('https://wa.me/5215654337226', '_blank')}
                                        className="mt-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition"
                                    >
                                        Contactar Soporte
                                    </button>
                                </>
                            )}

                            {status === 'success' && (
                                <>
                                    <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                                        Identidad Verificada
                                    </h3>
                                    <p className="font-bold text-green-600 mb-6">
                                        ¡Tu identidad ha sido verificada exitosamente!
                                    </p>
                                    <img src={draweSelfie} alt="Identidad verificada" />
                                </>
                            )}

                            {step === steps.truora && (
                                <div>
                                    <div className="flex justify-center text-center ">
                                        <button
                                            onClick={() => window.open(url, "_blank")}
                                            className="mt-2 px-4 py-2 bg-purple-800 text-white rounded-md hover:bg-purple-600 transition text-2xl"
                                            disabled={isLoading}
                                        >
                                            Ir a verificación de identidad
                                        </button>
                                    </div>
                                    <div>
                                        <img src={ConfirmUpload} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                </motion.div>
            )}
        </div>
    );
};

export default ValidateIdentity;
