import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import RootLayout from '../../components/Layout';
import ConfirmStepBackModal from '../../components/modals/ConfirmStepBackModal';
import { createPropertie, getPropertie, updateOrderPhoto, updatePropertie, uploadDocuments } from '../../services/properties.service';
import AddressInfo from './form-new/AddressInfo';
import AddressSearch from './form-new/AddressSearch';
import AgentType from './form-new/AgentType';
import DescriptionInfo from './form-new/DescriptionInfo';
import DescriptionPropertie from './form-new/DescriptionPropertie';
import PaymentRegisterInfo from './form-new/PaymentRegisterInfo';
import PaymentStep from './form-new/PaymentStep';
import PhotosInfo from './form-new/PhotosInfo';
import PlanInfo from './form-new/PlanInfo';
import Property from './form-new/Property';
import PropertyInfo from './form-new/PropertyInfo';
import PropertyType from './form-new/PropertyType';
import PublishInfo from './form-new/PublishInfo';
import SelectPlanAndPayment from './form-new/SelectPlanAndPayment';
import UploadInfo from './form-new/UploadInfo';
import UploadMain from './form-new/UploadMain';
import UploadPhotos from './form-new/UploadPhotos';
import RealState from './form-new/RealState';
import { getMeUserInfo } from '../../services/auth.service';
import PhotoProfile from './form-new/PhotoProfile';
import UploadPhotosPt2 from './form-new/UploadPhotospt2';

const MainPublish = ({ isEditing = false }) => {

    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);
    const [step, setStep] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [newPropertieId, setNewPropertieId] = useState();
    const [realPropertyId, setRealPropertyId] = useState();
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [hasDocuments, setHasDocuments] = useState([]);
    const [hasProfilePicture, setHasProfilePicture] = useState(false);
    const { id } = useParams();
    const [mainPhoto, setMainPhoto] = useState({
        category: "facade",
        imageIndex: 0
    });

    const [property, setProperty] = useState(null);

    // Estado formData con location en su estructura
    const [formData, setFormData] = useState({
        documentsUpload: { ine: "", passport: "" },
        utilsProperties: { rooms: [], bathrooms: [], parking: [], amenities: [], best_propertie: [], childrens: [], pets: [], for_rent: [], surface: '' },
        photosProperties: {},
        planSelect: {},
        descriptionsPropertie: { description: "", head: "", monthly_rent: null, is_negotiable: false, maintenance_included: false, maintenance: null, antiquity: 0, share_commission: false, },
        propertie: {},
        typePropertie: 0,
        agentType: null,
        location: {
            lat: 20.6736, lng: -103.344, postal_code: '', city: '', address_name: '', formatted_address: '', select: false
        },
        show_address: false,
    });

    const updateFormData = (field, value) => {
        setFormData((prevState) => ({ ...prevState, [field]: value }));
    };

    const onSubmitPropertyBeforePhotos = async (utilsProperties) => {


        let propertyId = id;
        const { descriptionsPropertie, photosProperties, location, documentsUpload, show_address } = formData;

        const rent_ids = [...new Set(utilsProperties.for_rent)].map(id => ({ id }));

        try {
            const payload = {
                property_type_id: formData.typePropertie,
                rooms: utilsProperties.rooms[0] === "loft" ? 1 : utilsProperties.rooms[0],
                is_loft: utilsProperties.rooms.includes("loft"),
                surface: utilsProperties.surface,
                bathrooms: utilsProperties.bathrooms[0],
                parking_lot: utilsProperties.parking[0],
                amenities: utilsProperties.amenities.map((a) => ({ id: a })),
                best_amenities: utilsProperties.best_propertie.map((a) => ({ id: a })),
                location: {
                    lat: location.lat,
                    lng: location.lng,
                    postal_code: location.postal_code,
                    city: location.city,
                    address_name: location.address_name,
                    formatted_address: location.formatted_address
                },
                is_pet_friendly: utilsProperties.pets[0],
                child_allowed: utilsProperties.childrens[0],
                rent_duration_ids: rent_ids,
                show_address: show_address,
            };


            const propertie = propertyId || newPropertieId
                ? await updatePropertie(propertyId || newPropertieId, payload, token)
                : await createPropertie(payload, token);


            setRealPropertyId(propertie.data.propertyId)


        }
        catch (error) {
            console.error("Error al crear la propiedad", error);
        }
    }

    // Función para manejar el submit
    const onSubmitAddPropertie = async () => {
        let propertyId = id;
        const { descriptionsPropertie, utilsProperties, photosProperties, location, documentsUpload, show_address } = formData;

        const rent_ids = [...new Set(utilsProperties.for_rent)].map(id => ({ id }));

        try {
            const payload = {
                title: descriptionsPropertie.head,
                description: descriptionsPropertie.description,
                property_type_id: formData.typePropertie,
                extra_info: descriptionsPropertie.extra_info,
                rooms: utilsProperties.rooms[0] === "loft" ? 1 : utilsProperties.rooms[0],
                is_loft: utilsProperties.rooms.includes("loft"),
                surface: utilsProperties.surface,
                maintenance: descriptionsPropertie.maintenance,
                maintenance_included: descriptionsPropertie.maintenance_included,
                bathrooms: utilsProperties.bathrooms[0],
                parking_lot: utilsProperties.parking[0],
                amenities: utilsProperties.amenities.map((a) => ({ id: a })),
                best_amenities: utilsProperties.best_propertie.map((a) => ({ id: a })),
                location: {
                    lat: location.lat,
                    lng: location.lng,
                    postal_code: location.postal_code,
                    city: location.city,
                    address_name: location.address_name,
                    formatted_address: location.formatted_address
                },
                is_negotiable: descriptionsPropertie.is_negotiable,
                daily_price: rent_ids.some(item => [1, 2, 3].includes(item.id)) ? descriptionsPropertie.daily_rent : 0,
                monthly_rent: rent_ids.some(item => [4].includes(item.id)) ? descriptionsPropertie.monthly_rent : 0,
                is_monthly_rent_exact: !!descriptionsPropertie.monthly_rent,
                is_pet_friendly: utilsProperties.pets[0],
                child_allowed: utilsProperties.childrens[0],
                rent_duration_ids: rent_ids,
                antiquity: descriptionsPropertie.years * 365,
                show_address: show_address,
                share_commission: descriptionsPropertie.share_commission,
            };

            const propertie = await updatePropertie(realPropertyId, payload, token)

            if (propertie.data) {
                const id_propertie = propertie.data.propertyId || propertyId;


                console.log('uploading documents');

                // Procesar documentos
                const documentsFormData = new FormData();
                if (documentsUpload.ine) documentsFormData.append("idFront", documentsUpload.ine);
                if (documentsUpload.ine_back) documentsFormData.append("idBack", documentsUpload.ine_back);
                if (documentsUpload.passport) documentsFormData.append("passportFront", documentsUpload.passport);
                if (documentsFormData.has("idFront") || documentsFormData.has("passportFront")) {
                    await uploadDocuments(documentsFormData, token);
                }

                console.log('setting new propertie id', { isEditing, propertyId, newPropertieId, id_propertie });

                if (!isEditing) {
                    setNewPropertieId(id_propertie);
                    console.log({ newPropertieId, step, steps, id_propertie, newStep: steps.length - 4 });
                    setStep(steps.length - 4);
                } else {
                    console.log('isEditing', isEditing);
                    setTimeout(() => {
                        navigate("/propiedad/" + id_propertie + "/detalle");
                    }, 500);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handlePreviousStep = () => setShowConfirmModal(true);
    const confirmPreviousStep = () => { setShowConfirmModal(false); setStep(step - 1); };

    useEffect(() => { if (newPropertieId) setStep(steps.length - 4); }, [newPropertieId]);
    useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, [step]);

    useEffect(() => {
        if (isEditing && id) {
            const fetchPropertyData = async () => {
                try {
                    const response = await getPropertie(id);
                    const propertyData = response.data.property;
                    console.log('propertyData', propertyData);
                    console.log(propertyData.images);

                    setFormData((prevState) => ({
                        ...prevState,
                        location: {
                            lat: propertyData.location.lat,
                            lng: propertyData.location.lng,
                            postal_code: propertyData.postal_code,
                            city: propertyData.city,
                            formatted_address: propertyData.location.formatted_address || propertyData.address,
                            address_name: propertyData.address || propertyData.location.formatted_address
                        },
                        utilsProperties: {
                            rooms: [propertyData.is_loft ? 'loft' : propertyData.rooms],
                            bathrooms: [propertyData.bathrooms],
                            parking: [propertyData.parking_lot],
                            amenities: propertyData.amenities.map(a => a.id),
                            best_propertie: propertyData.bestAmenities.map(a => a.id),
                            childrens: [propertyData.child_allowed],
                            pets: [propertyData.is_pet_friendly],
                            for_rent: propertyData.rent_durations.map(d => d.id),
                            surface: propertyData.surface
                        },
                        photosProperties: {
                            facade: propertyData.images.filter(img => img.image_type === 'Fachada').map(img => img.image),
                            bathrooms: propertyData.images.filter(img => img.image_type === 'Baños'),
                            rooms: propertyData.images.filter(img => img.image_type === 'Recámaras'),
                            other: propertyData.images.filter(img => img.image_type === 'Otros')
                        },
                        descriptionsPropertie: {
                            description: propertyData.description || '',
                            head: propertyData.title || '',
                            monthly_rent: propertyData.monthly_rent || null,
                            daily_rent: propertyData.daily_price || null,
                            is_negotiable: propertyData.is_negotiable || false,
                            maintenance_included: propertyData.maintenance_included || false,
                            maintenance: propertyData.maintenance || null,
                            years: (Number(propertyData.antiquity) / 365) || 0,
                            extra_info: propertyData.extra_info || '',
                            share_commission: propertyData.share_commission || false,
                        },
                        propertie: { id: propertyData.id, status: propertyData.status },
                        typePropertie: propertyData.property_type_id || 0,
                        show_address: propertyData.show_address
                    }));
                } catch (error) {
                    console.error('Error al cargar los datos de la propiedad:', error);
                }
            };
            fetchPropertyData();
        }
    }, [isEditing, id]);

    useEffect(() => {
        const fetchProperty = async (propertyId) => {
            try {
                const resp = await getPropertie(propertyId, token, true);
                setProperty(resp.data.property);
            } catch (error) {
                console.error('Error al obtener la propiedad:', error);
            }
        };

        if (newPropertieId) {
            fetchProperty(newPropertieId);
        }
    }, [newPropertieId, token]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await getMeUserInfo();
                const hasIdentityDocument = response.data.user.documents.some((document) => document.document_type === 'identity')
                setHasDocuments(hasIdentityDocument);
                setHasProfilePicture(response.data.user.profile_picture);
            }
            catch (error) {
                console.error("Error al traer userInfo", error);
            }
        }
        fetchUser();
    }, []);

    const steps = [
        !isEditing && <PublishInfo key="publish-info" step={step} contador={1} setStep={setStep} />,
        !isEditing && !hasProfilePicture && < PhotoProfile key="photo-profile" step={step} contador={1} setStep={setStep} />,
        !isEditing && <PropertyType key="property-type" step={step} setStep={setStep} contador={1} setTypePropertie={(type) => updateFormData('typePropertie', type)} />,
        (!isEditing && <AgentType key="agent-type" step={step} setStep={setStep} contador={0} setTypeAgent={(type) => updateFormData('agentType', type)} />),
        !isEditing && formData.agentType === 2 && (
            <RealState
                key="real-state"
                step={step}
                setStep={setStep}
                contador={1}
                setRealState={() => updateFormData('realState',)}
            />
        ),
        !isEditing && !hasDocuments && <UploadInfo key="upload-info" step={step} contador={2} setStep={setStep} />,
        !isEditing && !hasDocuments && <UploadMain key="upload-main" step={step} contador={2} setStep={setStep} setDocumentsUpload={(docs) => updateFormData('documentsUpload', docs)} documentsUpload={formData.documentsUpload} />,
        !isEditing && <AddressInfo key="address-info" step={step} contador={3} setStep={setStep} />,
        !isEditing && <AddressSearch handlePreviousStep={handlePreviousStep} contador={3} key="address-search" step={step} setStep={setStep} location={formData.location} show_address={formData.show_address} updateFormData={updateFormData} />,
        <PropertyInfo key="property-info" step={step} contador={4} setStep={setStep} isEditing={isEditing} />,
        <Property key="property" step={step} contador={4} setStep={setStep} setUtilsProperties={(utils) => updateFormData('utilsProperties', utils)} utilsProperties={formData.utilsProperties} onSubmitPropertyBeforePhotos={onSubmitPropertyBeforePhotos} />,
        <PhotosInfo key="photos-info" step={step} contador={5} setStep={setStep} />,
        //<UploadPhotos key="upload-photos" step={step} contador={5} setStep={setStep} setPhotosProperties={(photos) => updateFormData('photosProperties', photos)} photosProperties={formData.photosProperties} setMainPhoto={setMainPhoto} />,
        <UploadPhotosPt2 key="upload-photos2" step={step} contador={5} setStep={setStep} setPhotosProperties={(photos) => updateFormData('photosProperties', photos)} photosProperties={formData.photosProperties} setMainPhoto={setMainPhoto} propertyId={realPropertyId} />,
        <DescriptionInfo key="description-info" step={step} contador={6} setStep={setStep} />,
        <DescriptionPropertie key="description-propertie" step={step} contador={6} setStep={setStep} setDescriptionsPropertie={(desc) => updateFormData('descriptionsPropertie', desc)} utilsProperties={formData.utilsProperties} descriptionsPropertie={formData.descriptionsPropertie} token={token} onSubmitAddPropertie={onSubmitAddPropertie} typePropertie={formData.typePropertie} location={formData.location} show_address={formData.show_address} />,
        <PlanInfo key="plan-info" step={step} setStep={setStep} contador={7} />,
        <SelectPlanAndPayment key="select-plan-and-payment" step={step} contador={7} setStep={setStep} setPlanSelect={(plan) => updateFormData('planSelect', plan)} setSelectedPlan={setSelectedPlan} selectedPlan={selectedPlan} />,
        <PaymentRegisterInfo key="payment-register-info" step={step} contador={8} setStep={setStep} planSelect={formData.planSelect} selectedPlan={selectedPlan} property={property} newPropertieId={newPropertieId} />,
        <PaymentStep key="confirm-info" step={step} setStep={setStep} contador={8} formData={formData} selectedPlan={selectedPlan} propertyId={newPropertieId} />,
    ].filter(Boolean);

    return (
        <RootLayout admin>
            <div className="bg-gradient-to-r from-purple-500 to-indigo-500 h-14 md:h-[70px] -mt-5 md:mt-2"></div>
            <div className="flex flex-wrap justify-center items-start min-h-screen -mt-9">
                <div className={`relative flex items-center justify-center bg-white shadow-2xl rounded-3xl p-4 md:p-8 w-full mx-2 ${step !== 13 ? 'max-w-4xl' : ''}`}>
                    <div className="absolute top-4 right-4 cursor-pointer" onClick={() => navigate("/admin/dashboard")}>
                        <FontAwesomeIcon icon={faX} className="text-gray-500 hover:text-gray-700 transition duration-200" />
                    </div>
                    {steps[step]}
                </div>
            </div>

            <ConfirmStepBackModal show={showConfirmModal} onClose={() => setShowConfirmModal(false)} onConfirm={confirmPreviousStep} />
        </RootLayout>
    );
};

export default MainPublish;
